import { Switch } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { useCourse } from "../../hooks/useCourses";
import { PlanType } from "../../models/types";
import { createCourse, deleteCourse, updateCourse, updateCourseImage } from "../../services/CourseService";
import { CustomInput } from "../CustomInput";
import BaseModal from "./BaseModal";
import ConfirmationModal from "./ConfirmationModal";

type CourseCreationModalProps = {
    open: boolean;
    onClose: () => void;
    courseId: string | null;
}

export default function CourseCreationModal({ open, onClose, courseId }: CourseCreationModalProps) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [visible, setVisible] = useState<boolean>(false);
    const [priority, setPriority] = useState<string>("1");
    const [highlight, setHighlight] = useState<boolean>(false);
    const [free, setFree] = useState<boolean>(false);
    const [plan, setPlan] = useState<PlanType>("SUBSCRIBER");
    const navigate = useNavigate();
    const { data: course, isLoading } = useCourse(courseId);

    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const { setNotification } = useAppContext();

    useEffect(() => {
        if (course) {
            setName(course.title);
            setDescription(course.description)
            setVisible(course.visible);
            setPriority(`${course.priority}`);
            setHighlight(course.highlight);
            setFree(course.free);
            setPlan(course.plan);
        } else {
            setName("");
            setDescription("");
            setVisible(false);
            setPriority("1");
            setHighlight(false);
            setFree(false);
            setPlan("SUBSCRIBER");
        }
    }, [course, open])

    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        if (file && courseId) {
            updateCourseImage(courseId, file);
        }
    };

    const handleDeleteCourse = async () => {
        try {
            if (courseId) {
                await deleteCourse(courseId);
                setNotification({
                    type: 'success',
                    message: 'Curso excluido com sucesso',
                    title: 'Curso excluido'
                })
                navigate("/admin")
                onClose();
                setOpenConfirmation(false);
            } else {
                setNotification({
                    type: 'error',
                    message: 'Nenhum curso selecionado',
                    title: 'Erro ao excluir curso'
                })
            }
        } catch (error) {
            setNotification({
                type: 'error',
                message: 'Tente novamente',
                title: 'Erro ao excluir curso'
            })
        }
    }

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (name === "") {
                return;
            }
            if (description === "") {
                return;
            }
            if (courseId) {
                await updateCourse(courseId, {
                    title: name,
                    description,
                    visible,
                    priority: parseInt(priority),
                    highlight,
                    free,
                    plan
                })
            } else {
                await createCourse({
                    title: name,
                    description,
                    visible,
                    priority: parseInt(priority),
                    highlight,
                    free,
                    plan
                })
            }
            onClose();
            setNotification({
                type: 'success',
                message: `Curso ${courseId ? "editado" : "adicionado"} com sucesso`,
                title: `Curso`
            })
        } catch (error) {
            setNotification({
                type: 'error',
                message: `Erro ao ${courseId ? "editar" : "adicionar"} o curso, tente novamente`,
                title: `Curso`
            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <BaseModal open={open} onClose={onClose}>
            <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold">{courseId ? "Editar Curso" : "Criar Curso"}</h2>
                    <div className="flex items-center gap-2">
                        {courseId && <span onClick={() => setOpenConfirmation(true)} className={`cursor-pointer inline-flex items-center bg-red-300 gap-x-1.5 rounded-md px-2 py-1 text-xs font-light text-red-900 ring-1 ring-inset ring-gray-200 hover:ring-gray-300 hover:bg-red-400 hover:text-white`}>
                            Excluir
                        </span>}
                        <XMarkIcon onClick={() => onClose()} className="h-5 w-5 cursor-pointer" />
                    </div>
                </div>
                <h3 className="text-base font-semibold leading-6 text-gray-900">Ativo</h3>
                <Switch
                    checked={visible}
                    onChange={setVisible}
                    className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                >
                    <span className="sr-only">Use setting</span>
                    <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                    <span
                        aria-hidden="true"
                        className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-indigo-600"
                    />
                    <span
                        aria-hidden="true"
                        className="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                    />
                </Switch>
                <CustomInput title="Titulo"
                    description="Informe um titulo para o curso"
                    error={""}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    value={name}
                    placeholder="Titulo do curso"
                    name="course-name"
                    id="course-name"
                    type="text"
                />
                <CustomInput title="Descrição"
                    description="Informe uma descrição para o curso"
                    error={""}
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }}
                    value={description}
                    placeholder="Descrição do curso"
                    name="course-description"
                    id="description-name"
                    type="text"
                />
                <CustomInput title="Número do curso"
                    description="Informe um número para o curso"
                    error={""}
                    onChange={(e) => {
                        setPriority(e.target.value)
                    }}
                    value={priority}
                    placeholder="Número do curso"
                    name="course-number"
                    id="course-number"
                    type="text"
                />
                <h3 className="text-xs font-semibold">Plano:</h3>
                <select
                    id="subscriptionType"
                    value={plan}
                    onChange={(e) => setPlan(e.target.value as PlanType)}
                    className="w-64 p-2 text-gray-700 bg-white border border-gray-300 text-xs rounded-md shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500"
                >
                    <option value="" disabled>
                        -- Choose an option --
                    </option>
                    <option value="FREE">Free</option>
                    <option value="OSD">OSD</option>
                    <option value="SUBSCRIBER">Subscriber</option>
                </select>
                <h3 className="text-base font-semibold leading-6 text-gray-900">Destaque na tela inicial</h3>
                <Switch
                    checked={highlight}
                    onChange={setHighlight}
                    className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                >
                    <span className="sr-only">Use setting</span>
                    <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                    <span
                        aria-hidden="true"
                        className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-indigo-600"
                    />
                    <span
                        aria-hidden="true"
                        className="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                    />
                </Switch>
                <h3 className="text-base font-semibold leading-6 text-gray-900">Aula gratuita</h3>
                <Switch
                    checked={free}
                    onChange={setFree}
                    className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                >
                    <span className="sr-only">Use setting</span>
                    <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                    <span
                        aria-hidden="true"
                        className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-indigo-600"
                    />
                    <span
                        aria-hidden="true"
                        className="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                    />
                </Switch>
                {courseId && <>
                    <h3 className="text-base font-semibold leading-6 text-gray-900">Imagem</h3>
                    <div className="flex flex-col gap-2 items-center">
                        <img src={course?.image ?? "/images/no-image.png"} alt={course?.title} className="w-36 h-36 shadow rounded-xl overflow-hidden" />
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            className="text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-100 file:text-blue-700 hover:file:bg-blue-200 mt-1"
                        />
                    </div>
                </>}
                <button disabled={loading} onClick={() => {
                    handleSubmit();
                }} className="bg-indigo-600 hover:bg-indigo-700 rounded-md text-white text-sm font-medium py-1">{courseId ? "Salvar Alterações" : "Criar Curso"}</button>
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    onConfirm={() => handleDeleteCourse()}
                    onCancel={() => setOpenConfirmation(false)}
                    title="Excluir Meta"
                    message={`Deseja realmente excluir a meta ${course?.title}`} />
            </div>
        </BaseModal>
    )
}
