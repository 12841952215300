import { useEffect, useState } from "react";
import { Button } from "../components/Button";
import Card from "../components/Card";
import { GroupedTransaction } from "../components/GroupedTransaction";
import TransactionModal from "../components/modals/TransactionCategoriesModal";
import { useBudgetSettings } from "../hooks/useBudgetSettings";
import { useTransactions } from "../hooks/useTransactions";
import { Transaction, TransactionCategory } from "../models/types";
import { formatBrazilianCurrence } from "../utils/helper";


export default function RecurrenceTransactionPage() {
    const [groupedTransactions, setGroupedTransactions] = useState<{ category: TransactionCategory, transactions: Transaction[]; total: number; }[]>([]);
    const [openCreateTransaction, setOpenCreateTransaction] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState<string | null>(null);
    const { data: settings } = useBudgetSettings();
    const { data: transactions } = useTransactions({
        page_size: 50,
    })

    useEffect(() => {
        if (transactions) {
            const result = Object.values(
                transactions?.results?.reduce<Record<string, { category: TransactionCategory; transactions: Transaction[]; total: number; }>>((acc, transaction) => {
                    const key = transaction?.category_name?.id ?? "Sem categoria"; // Group by category_name.id
                    if (!acc[key]) {
                        acc[key] = {
                            category: transaction.category_name ?? {
                                name: "Sem categoria",
                                id: "Sem categoria",
                                category_type: "expense",
                                budget_amount: null,
                            },
                            transactions: [],
                            total: 0,
                        };
                    }
                    acc[key].transactions.push(transaction);
                    acc[key].total += Number(transaction.amount);
                    return acc;
                }, {})
            );
            setGroupedTransactions(result);
        } else {
            setGroupedTransactions([]);
        }

    }, [transactions])

    const biggestExpense = groupedTransactions.length > 0 ? groupedTransactions.reduce((acc, group) => acc.total > group.total ? acc : group).category.name : "";

    return <Card>
        <TransactionModal
            transactionId={selectedTransaction}
            open={openCreateTransaction}
            onClose={() => {
                setOpenCreateTransaction(false)
                setSelectedTransaction(null);
            }}
        />
        <div className="flex gap-2 items-center w-full justify-between">
            <h1 className="text-xl font-bold capitalize">Despesas Fixas</h1>
            <Button title="Adicionar Despesa" onClick={() => {
                setOpenCreateTransaction(true);
            }} />
        </div>
        <h4 className="text-sm text-gray-500">
            Organize e controle suas despesas fixas de forma eficiente. Aqui você pode adicionar, editar e gerenciar todas as suas contas mensais, como aluguel, empréstimos, água, luz, internet, e muito mais. Mantenha suas finanças em dia, evitando esquecimentos e surpresas no final do mês. Configure suas despesas fixas agora mesmo!
        </h4>
        <div className="flex w-full flex-col md:flex-row flex-grow p-2 border border-gray-300 rounded-sm">
            <div className="flex flex-col px-0 md:px-2 py-2 md:py-0 w-full md:w-max flex-grow border-b md:border-b-0 md:border-r md:border-solid border-gray-300">
                <h4 className="text-xs text-gray-500 text-center">Seu maior gasto</h4>
                {biggestExpense && <h4 className="text-center font-bold text-red-400 text-md">{biggestExpense}</h4>}
                {!biggestExpense && <h4 className="text-center font-bold text-gray-400 text-sm">Nenhuma despesa cadastrada</h4>}
            </div>
            <div className="flex flex-col px-0 md:px-2 py-2 md:py-0 w-full md:w-max flex-grow border-b md:border-b-0 md:border-r md:border-solid border-gray-300">
                <h4 className="text-xs text-gray-500 text-center">Receita Mensal</h4>
                {settings && settings?.results?.length > 0 && <h4 className="text-center font-bold text-green-400 text-md">R${formatBrazilianCurrence(settings.results[0].base_income)}</h4>}
                {settings && settings?.results?.length === 0 && <h4 className="text-center font-bold text-gray-400 text-sm">Nenhuma renda cadastrada</h4>}
            </div>
            <div className="flex flex-col px-0 md:px-2 py-2 md:py-0 w-full md:w-max flex-grow">
                <h4 className="text-xs text-gray-500 text-center">Despesa total</h4>
                <h4 className="text-center font-bold text-red-400 text-md">R${formatBrazilianCurrence(groupedTransactions.reduce((acc, group) => acc + group.total, 0).toFixed(2))}</h4>
            </div>
        </div>
        {groupedTransactions.map((group, idx) => <GroupedTransaction onSelectTransaction={(id: string) => {
            setSelectedTransaction(id);
            setOpenCreateTransaction(true);
        }} total={group.total} key={idx} category={group.category} transactions={group.transactions} />)}
    </Card>
}