import { CreditCardIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { CreditCardTransaction, TableColumn } from "../../models/types";
import { formatBrazilianCurrence } from "../../utils/helper";

export const creditCardTransactionColumns: TableColumn<CreditCardTransaction>[] = [
    {
        field: "credit_card_name",
        header: "Despesas",
        render: (t) => <div className="flex flex-row gap-1 items-center">
            <div className="flex flex-col gap-1">
                {t.first_transaction_date && <h4 className="text-xs font-semibold max-w-full truncate text-gray-900">{format(t.first_transaction_date, "dd/MM/yyyy")}</h4>}
                <div className="flex flex-row gap-1 items-center">
                    <CreditCardIcon className="w-10 h-10" style={{
                        color: t.credit_card_name.color,
                    }} />
                    <div className="flex flex-col">
                        <h4 className="text-xs font-light max-w-full truncate text-gray-900">{t.credit_card_name.name}</h4>
                        <h4 className="text-xs text-gray-900 truncate max-w-full">{t.name}</h4>
                    </div>
                </div>
            </div>
        </div>,
        className: "min-w-40 max-w-20 truncate",
    },
    {
        field: "amount",
        header: "",
        render: (t) =>
            <div className="flex justify-end items-end flex-col">
                <h4 className={`text-xs text-gray-900`}>Valor</h4>
                <h4 className={`text-sm text-red-600`}>R$ {formatBrazilianCurrence(t.amount)}</h4>
                <h4 className={`text-xs text-gray-900`}>Parcela {t.installment_number}/{t.installment_amount}</h4>
            </div>
    },
]