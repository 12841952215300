import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { uploadOfxFile } from "../../services/MonthlyTransactionService";
import { Button } from "../Button";
import UploadFile from "../UploadFile";
import BaseModal from "./BaseModal";

type ImportTransactionsModalProps = {
    open: boolean;
    onClose: () => void;
}

export default function ImportTransactionsModal({ open, onClose }: ImportTransactionsModalProps) {
    const [file, setFile] = useState<File | null>(null);
    const { setNotification } = useAppContext();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    useEffect(() => {
        setFile(null);
    }, [open]);

    const handleImport = async () => {
        try {
            setLoading(true);
            if (!file) {
                return;
            }
            const { data } = await uploadOfxFile(file);
            navigate(`/importar-transacoes`, { state: { transactions: data.transactions } });
        } catch (e) {
            setNotification({
                message: "Erro ao importar arquivo",
                type: "error",
                title: "Erro"
            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <BaseModal open={open} onClose={() => onClose()}>
            <div className="flex w-full flex-col gap-1 max-w-xs md:max-w-full">
                <h3 className="text-md md:text-lg font-semibold text-left">Importar Transações</h3>
                <h3 className="text-sm md:text-xs text-gray-500 mt-1 text-left">Importe arquivos OFX para facilitar a importação das suas transações.</h3>
                <UploadFile file={file} setFile={setFile} />
                <Button disabled={!file} onClick={() => handleImport()} loading={loading} className={`${!file ? "bg-gray-300 hover:bg-gray-300 cursor-default" : "bg-blue-600 hover:bg-blue-700"} rounded-sm mt-2`} title="Importar" />
                <button className="text-red-600 mt-2 underline hover:no-underline text-xs font-semibold" onClick={() => onClose()}>Cancelar</button>
            </div>
        </BaseModal>
    )
}
