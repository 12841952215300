import queryClient, { QueryKey } from "../hooks/state";
import { CreateCreditCardTransaction, CreditCardSummaryFetchParams, CreditCardTransaction, CreditCardTransactionFetchParams, CreditCardTransactionSummary, PaginatedResponse, UpdateCreditCardTransaction } from "../models/types";
import { httpDeleteAuthenticated, httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./Api";

const endpoints = {
  list: () => "/central/central/credit_card_transactions",
  retrieve: (id: string) => `/central/central/credit_card_transactions/${id}`,
};

export const getCreditCardTransaction = async (objectId: string) => {
  return httpGetAuthenticated<CreditCardTransaction>(endpoints.retrieve(objectId));
}

export const getCreditCardTransactions = async (params: CreditCardTransactionFetchParams) => {
  return httpGetAuthenticated<PaginatedResponse<CreditCardTransaction>>(endpoints.list(), {
    params,
  });
}

export const createCreditCardTransaction = async (payload: CreateCreditCardTransaction) => {
  const response = await httpPostAuthenticated<CreditCardTransaction>(endpoints.list(), payload);
  queryClient.invalidateQueries(QueryKey.creditCardTransaction);
  return response.data;
}

export const updateCreditCardTransaction = async (id: string, payload: UpdateCreditCardTransaction) => {
  const response = await httpPatchAuthenticated(endpoints.retrieve(id), payload);
  queryClient.invalidateQueries(QueryKey.creditCardTransaction);
  return response.data;
}

export const deleteCreditCardTransaction = async (id: string) => {
  const response = await httpDeleteAuthenticated(endpoints.retrieve(id));
  queryClient.invalidateQueries(QueryKey.creditCardTransaction);
  return response.data;
}

export const getCreditCardTransactionSummary = async (params: CreditCardSummaryFetchParams) => {
  return await httpGetAuthenticated<CreditCardTransactionSummary>(endpoints.list() + "/summary", { params });
}