import { useState } from "react";
import { useUserSummary } from "../../hooks/useUsers";
import { User } from "../../models/types";
import { EditIcon } from "../Button";
import ExpenseSummary from "../ExpensesSummary";
import LoadingSpinner from "../Loading";
import BaseModal from "./BaseModal";
import UserPlanModal from "./UserPlanModal";

type UserSummaryModalProps = {
    open: boolean;
    onClose: () => void;
    userData: User;
}

export default function UserSummaryModal({ open, onClose, userData }: UserSummaryModalProps) {
    const { data: user, isLoading } = useUserSummary({ id: userData.id });
    const [edit, setEdit] = useState(false);
    return (
        <BaseModal open={open} onClose={onClose}>
            <UserPlanModal open={edit} onClose={() => setEdit(false)} user={userData} />
            <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between">
                    <h4 className="text-lg font-semibold">Métricas de uso</h4>
                    <EditIcon onClick={() => setEdit(true)} />
                </div>
                {!user && <LoadingSpinner />}
                {user &&
                    <>
                        <ExpenseSummary values={[
                            { label: "Videos Assistidos", value: `${user?.watched_videos_count}`, className: "text-gray-400" },
                            { label: "Metas Cadastradas", value: `${user?.goals_count}`, className: "text-gray-400" },
                        ]} />
                        <ExpenseSummary values={[
                            { label: "Despesas Fixas", value: `${user?.transactions_count}`, className: "text-gray-400" },
                            { label: "Transações Registradas", value: `${user?.monthly_transactions_count}`, className: "text-gray-400" },
                        ]} />
                        <h4 className="text-lg font-semibold">Métricas da IA</h4>
                        <ExpenseSummary values={[
                            { label: "Relatórios Pendentes", value: `${user?.ai_summaries_pending_count}`, className: "text-red-400" },
                            { label: "Relatórios Gerados", value: `${user?.ai_summaries_generated_count}`, className: "text-green-400" },
                        ]} />
                    </>
                }
            </div>
        </BaseModal>
    )
}
