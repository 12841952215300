

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

type AdminTabsProps = {
    current: string;
    setCurrent: (val: string) => void;
    tabs: {
        name: string;
    }[];
}

export default function AdminTabs({
    current,
    setCurrent,
    tabs,
}: AdminTabsProps) {
    return (
        <div className="mb-3">
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                    id="tabs"
                    name="tabs"
                    onChange={(e) => setCurrent(e.target.value)}
                    defaultValue={"Cursos"}
                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                >
                    {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                        {tabs.map((tab) => (
                            <div
                                key={tab.name}
                                onClick={() => setCurrent(tab.name)}
                                aria-current={current === tab.name}
                                className={classNames(
                                    current === tab.name
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                                    'flex whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                                )}
                            >
                                {tab.name}
                            </div>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    )
}
