import CourseCard from "../components/CourseCard";
import PaginatedCard from "../components/Pagination";
import TitleCard from "../components/TitleCard";
import { useCourses } from "../hooks/useCourses";
import { Course, CourseFilterParams } from "../models/types";

export default function CoursesPage() {
    return <div className="bg-white shadow sm:rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:p-6 max-w-full">
            <div className="flex flex-col gap-4">
                <TitleCard title={`Cursos em andamento`} subtitle="Não deixe seu progresso estagnar, continue de onde parou" titleBoxClassName="md:flex-col md:items-start">
                    <PaginatedCard<Course, CourseFilterParams>
                        useGetData={useCourses}
                        pageSize={5}
                        emptyComponent={<div className="text-gray-500 text-sm">Você ainda não iniciou nenhum curso.</div>}
                        params={{ started: true }}
                        render={(course) => <CourseCard key={course.id} course={course} />} />
                </TitleCard>
                <TitleCard title={`Todos os Cursos`} titleBoxClassName="md:flex-col md:items-start">
                    <PaginatedCard<Course, CourseFilterParams>
                        useGetData={useCourses}
                        pageSize={5}
                        params={{ started: false }}
                        render={(course) => <CourseCard key={course.id} course={course} />} />
                </TitleCard>
            </div>
        </div>
    </div>
}