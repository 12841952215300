import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { InputHTMLAttributes } from "react";

type MainInputProps = {
    title: string;
    error: string;
} & InputHTMLAttributes<HTMLInputElement>;

export default function MainInput(props: MainInputProps) {
    return <div className="flex flex-col gap-1 w-full">
        <h3 className="text-xs font-semibold text-gray-900">{props.title}</h3>
        <input
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            className={props.error !== "" ?
                `w-full h-7 rounded-md border-0 py-1.5 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 text-xs sm:leading-6`
                :
                `w-full h-8 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-xs sm:leading-6`}

        />
        {
            props.error !== "" && <div className="flex flex-row items-center gap-1">
                <ExclamationCircleIcon aria-hidden="true" className="h-5 w-5 text-red-500" />
                <h3 className="text-xs text-red-900">{props.error}</h3>
            </div>
        }
    </div >
}