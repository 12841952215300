import { FunctionComponent, ReactNode, createContext, useContext, useState } from "react";
import { Notification } from "../models/types";

interface IAppContext {
    notification: Notification | null;
    setNotification: (notification: Notification | null) => void;
    paymentModal: boolean;
    setPaymentModal: (paymentModal: boolean) => void;
}

interface AppProviderProps {
    children: ReactNode;
}

export const AppContext = createContext<IAppContext>({
    notification: null,
    setNotification: (notification: Notification | null) => { },
    paymentModal: false,
    setPaymentModal: (paymentModal: boolean) => { },
});
export const AppProvider: FunctionComponent<AppProviderProps> = ({ children }) => {
    const [notification, setNotification] = useState<Notification | null>(null);
    const [paymentModal, setPaymentModal] = useState<boolean>(false);
    return <AppContext.Provider value={{ notification, setNotification, paymentModal, setPaymentModal }}>{children}</AppContext.Provider>
}

export const useAppContext = () => useContext(AppContext);
