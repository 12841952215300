import { CategoryBadgeText } from "../../components/Badge";
import { TableColumn, TransactionCategory } from "../../models/types";

export const categoriesColumns: TableColumn<TransactionCategory>[] = [
    {
        field: "name",
        header: "Nome",
        className: "max-w-20 truncate",
        render: (t) => <h4 className="text-sm truncate text-gray-500">{t.name}</h4>,
    },
    {
        field: "category_type",
        header: "Tipo",
        className: "w-10",
        render: (t) => <CategoryBadgeText category={t} />,
    },

]