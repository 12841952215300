import { twMerge } from "tailwind-merge";
import { ThreeDotsItemsType } from "../models/types";
import ThreeDotsMenu from "./ThreeDotsMenu";

type CardHeaderProps = {
    title: string;
    subtitle?: string;
    className?: string;
    actions?: ThreeDotsItemsType[];
}

export default function CardHeader({ title, subtitle, className, actions }: CardHeaderProps) {
    return <div className={twMerge("flex w-full py-2 px-4 flex-row items-center md:flex-row justify-between bg-indigo-900 rounded-xl rounded-b-none", className)}>
        <div className="flex flex-col md:flex-col gap-1 items-start">
            <h3 className="text-sm/6 font-semibold text-white">{title}</h3>
            {subtitle && <h3 className="text-xs font-normal text-white">{subtitle}</h3>}
        </div>
        {actions && <ThreeDotsMenu popOverClassName="text-white" items={actions} className="flex" />}
    </div>

}