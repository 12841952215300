import { Field, Label, Switch } from "@headlessui/react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../components/Button";
import PrivacyPolicyModal from "../components/modals/PrivacyPolicy";
import { getCourseModules, getNextClass } from "../services/CourseModuleService";
import { createAccount } from "../services/LoginService";
import { setCookie } from "../utils/cookies";

export default function SignupPage() {
    const [searchParams] = useSearchParams();
    const origin = searchParams.get("origin");
    const redirectVideo = searchParams.get("redirect");
    const [enabled, setEnabled] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [policyError, setPolicyError] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [url, setUrl] = useState("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordError, setNewPasswordError] = useState<string>("");
    const navigate = useNavigate();
    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleCheckPassword = ({ onChange, value }: { onChange: (val: string) => void; value: string }) => {
        if (!/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value)) {
            setNewPasswordError("A senha deve conter pelo menos 8 caracteres, uma letra e um número");
        } else {
            setNewPasswordError("");
        }
        onChange(value);
    }

    const formatPhoneNumber = (value: string) => {
        // Remove qualquer caractere que não seja número
        const cleanedValue = value.replace(/\D/g, '');

        // Aplica a formatação para telefones fixos e celulares
        if (cleanedValue.length <= 10) {
            // Formato para telefone fixo: (XX) XXXX-XXXX
            return cleanedValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        } else {
            // Formato para celular: (XX) XXXXX-XXXX
            return cleanedValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        }
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (!enabled) {
                setPolicyError(true);
            } else {
                setPolicyError(false);
            }
            if (name === "" || (name && name.split(" ").length < 2)) {
                setNameError("Preencha seu nome completo");
            } else {
                setNameError("");
            }
            if (newPassword === "" || !/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(newPassword)) {
                setNewPasswordError("A senha deve conter pelo menos 8 caracteres, uma letra e um número");
            } else {
                setNewPasswordError("");
            }
            if (email === "" || !validateEmail(email)) {
                setEmailError("Informe um e-mail válido");
            } else {
                setEmailError("");
            }
            if (phone === "") {
                setPhoneError("Preencha seu telefone");
            } else {
                setPhoneError("");
            }
            if (name === "" || email === "" || phone === "" || !enabled || !validateEmail(email) || newPassword === "") {
                return;
            }
            const first_name = name.split(" ")[0];
            const last_name = name.split(" ")[1];
            const response = await createAccount({ first_name, ...(origin && { origin }), last_name, email, phone, password: newPassword });
            try {
                if (typeof window.fbq !== 'undefined') {
                    window.fbq('track', 'Lead');
                }
            } catch (e) {

            }
            if (response.status === 201) {
                setCookie("token", response.data.access_token);
                try {
                    if (redirectVideo) {
                        const { data } = await getCourseModules({ course: redirectVideo, page: 1, page_size: 1 });
                        if (data.results.length > 0) {
                            const { data: nextClass } = await getNextClass(data.results[0].id);
                            if (nextClass) {
                                navigate(`/curso/${redirectVideo}/modulo/${nextClass.module_id}/aula/${nextClass.class_id}`);
                            } else {
                                navigate(`/curso/${redirectVideo}`);
                            }
                        } else {
                            navigate(`/curso/${redirectVideo}`);
                        }
                    } else {
                        navigate("/inicio");
                    }
                } catch (e) {
                    navigate("/inicio");
                }
            }
            setError("");
        } catch (e) {
            setError("Erro ao enviar formulário, tente novamente mais tarde");
        } finally {
            setLoading(false);
        }

    }

    return (
        <div className="relative w-full min-h-screen isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
            <PrivacyPolicyModal open={open} onClose={() => setOpen(false)} />
            <img
                alt=""
                src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
                className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
            />
            <div
                aria-hidden="true"
                className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
            >
                <div
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                />
            </div>
            <div
                aria-hidden="true"
                className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
            >
                <div
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                />
            </div>
            <div className="w-full flex items-center flex-col px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Aprenda a investir e organizar suas finanças</h2>
                    <p className="mt-6 text-md leading-8 text-gray-300">
                        Crie uma conta para acessar nosso material gratuito.
                    </p>
                </div>
                <div className="w-full flex flex-col gap-5 mt-4 max-w-2xl">
                    <div className="w-full flex flex-col gap-2">
                        <label htmlFor="nome" className="text-md font-medium leading-6 text-white">
                            Nome Completo
                        </label>
                        <input
                            id="nome"
                            name="nome"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Nome completo"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {nameError !== "" && <h4 className="text-sm text-red-600">{nameError}</h4>}
                    </div>
                    <div className="w-full flex flex-col gap-2">
                        <label htmlFor="email" className="text-md font-medium leading-6 text-white">
                            Email
                        </label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="email@exemplo.com"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {emailError !== "" && <h4 className="text-sm text-red-600">{emailError}</h4>}
                    </div>
                    <div className="w-full flex flex-col gap-2">
                        <label htmlFor="phone" className="text-md font-medium leading-6 text-white">
                            Telefone
                        </label>
                        <input
                            id="phone"
                            name="phone"
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(formatPhoneNumber(e.target.value))}
                            placeholder="(xx) xxxxx-xxxx"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    {phoneError !== "" && <h4 className="text-sm text-red-600">{phoneError}</h4>}
                    <div className="flex flex-grow flex-col gap-2 min-w-72">
                        <label className="text-md text-white">Senha de acesso</label>
                        <input value={newPassword} placeholder="********" onChange={(e) => handleCheckPassword({ onChange: setNewPassword, value: e.target.value })} type="password" className="w-full border border-gray-200 rounded-md px-3 py-2" />

                    </div>
                    {newPasswordError !== "" && <h4 className="text-sm text-red-600">{newPasswordError}</h4>}
                    <div className="w-full flex flex-col gap-2">
                        <Field className="flex items-center">
                            <Switch
                                checked={enabled}
                                onChange={setEnabled}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
                            >
                                <span
                                    aria-hidden="true"
                                    className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                />
                            </Switch>
                            <Label as="span" className="ml-3 text-sm">
                                <span className="font-medium text-white">Eu concordo com a </span><span onClick={() => setOpen(true)} className="font-medium text-blue-600 cursor-pointer hover:underline">política de privacidade</span>
                            </Label>
                        </Field>
                    </div>
                    {policyError && <h4 className="text-sm text-red-600">Para cadastrar-se é necessário aceitar os termos</h4>}
                    <Button disabled={loading} loading={loading} className="text-center py-3 text-md font-bold" onClick={() => handleSubmit()} title="Cadastrar" />
                    {error !== "" && <h4 className="text-sm text-red-600">{error}</h4>}
                    {url !== "" && <h4 className="text-sm font-bold text-white">Formulário enviado com sucesso, caso o link não abra automaticamente <a href={url} className="text-blue-600">clique aqui</a></h4>}
                </div>
            </div>
        </div>
    )
}
