import { twMerge } from "tailwind-merge";
import { ThreeDotsItemsType } from "../models/types";
import CardHeader from "./CardHeader";

type TitleCardProps = {
    title: string;
    subtitle?: string;
    children: React.ReactNode;
    titleBoxClassName?: string;
    boxClassName?: string;
    actions?: ThreeDotsItemsType[];
}

export default function TitleCard({ title, subtitle, children, titleBoxClassName, boxClassName, actions }: TitleCardProps) {
    return <div className="flex w-full flex-col">
        <CardHeader title={title} subtitle={subtitle} className={titleBoxClassName} actions={actions} />
        <div className={twMerge("flex py-2 px-4 w-full border-l border-r border-b border-gray-300 rounded-xl rounded-t-none", boxClassName)}>
            {children}
        </div>
    </div>
}