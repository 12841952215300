import queryClient, { QueryKey } from "../hooks/state";
import { Course, CourseFilterParams, CourseSummaryResponse, PaginatedResponse, PlanType } from "../models/types";
import { httpDeleteAuthenticated, httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./Api";

const endpoints = {
  list: () => "/course/courses",
  retrieve: (id: string) => `/course/courses/${id}`,
  admin_summary: () => "/admin/course_summary"
};

export const getCourse = async (objectId: string) => {
  return httpGetAuthenticated<Course>(endpoints.retrieve(objectId));
}

export const getCourses = async (params: CourseFilterParams) => {
  return httpGetAuthenticated<PaginatedResponse<Course>>(endpoints.list(), {
    params,
  });
}

export const createCourse = async (payload: {
  title: string;
  description: string;
  visible: boolean;
  highlight: boolean;
  priority: number;
  free: boolean;
  plan: PlanType;
}) => {
  const response = await httpPostAuthenticated<Course>(endpoints.list(), payload);
  queryClient.invalidateQueries(QueryKey.course);
  return response.data;
}

export const updateCourseImage = async (id: string, image: any) => {
  const formData = new FormData();
  formData.append("image", image);
  const response = await httpPatchAuthenticated(endpoints.retrieve(id), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  queryClient.invalidateQueries(QueryKey.course);
  return response.data;
}

export const updateCourse = async (id: string, payload: {
  title: string;
  description: string;
  visible: boolean;
  highlight: boolean;
  priority: number;
  free: boolean;
  plan: PlanType;
}) => {
  const response = await httpPatchAuthenticated(endpoints.retrieve(id), payload);
  queryClient.invalidateQueries(QueryKey.course);
  return response.data;
}

export const deleteCourse = async (id: string) => {
  const response = await httpDeleteAuthenticated(endpoints.retrieve(id));
  queryClient.invalidateQueries(QueryKey.course);
  return response.data;
}

export const getCourseAdminSummary = async () => {
  return httpGetAuthenticated<CourseSummaryResponse[]>(endpoints.admin_summary());
}