import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { PlanType, User } from "../../models/types";
import { sendMagicLink, updateUserPlan } from "../../services/UserService";
import { Button } from "../Button";
import BaseModal from "./BaseModal";
import ConfirmationModal from "./ConfirmationModal";

type UserPlanModalProps = {
    open: boolean;
    onClose: () => void;
    user: User;
}

export default function UserPlanModal({ open, onClose, user }: UserPlanModalProps) {
    const [selectedPlan, setSelectedPlan] = useState<PlanType>(user.plan);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const { setNotification } = useAppContext();
    const [loading, setLoading] = useState(false);
    const handleSubmit = async () => {
        try {
            setLoading(true);
            await updateUserPlan(user.id, { plan: selectedPlan });
            onClose();
            setNotification({
                message: "Usuário atualizado com sucesso",
                type: "success",
                title: "Sucesso"
            })
        } catch (e) {
            setNotification({
                message: "Erro ao atualizar plano do usuário",
                type: "error",
                title: "Erro"
            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <BaseModal open={open} onClose={() => onClose()}>
            <div className="flex w-full flex-col gap-2">
                <div className="flex flex-row items-center justify-between w-full">
                    <h3 className="text-md font-semibold">Editar Usuário</h3>
                    <EnvelopeIcon className="h-6 w-6 text-indigo-700 hover:text-indigo-800 cursor-pointer" onClick={() => setOpenConfirmation(true)} />
                </div>
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    message="Deseja realmente enviar um magic link para esse usuário?"
                    onCancel={() => setOpenConfirmation(false)}
                    onConfirm={() => {
                        if (user.external_id) {
                            sendMagicLink({ user: user.external_id })
                        }
                        setOpenConfirmation(false);
                        setNotification({
                            message: "Magic link enviado com sucesso",
                            type: "success",
                            title: "Sucesso"
                        })
                    }}
                    title="Enviar Magic Link"
                />
                <div className="flex flex-row gap-1">
                    <h3 className="text-xs font-semibold">Nome:</h3>
                    <h3 className="text-xs">{user.first_name} {user.last_name}</h3>
                </div>
                <div className="flex flex-row gap-1">
                    <h3 className="text-xs font-semibold">E-mail:</h3>
                    <h3 className="text-xs">{user.email}</h3>
                </div>
                <h3 className="text-xs font-semibold">Plano:</h3>
                <select
                    id="subscriptionType"
                    value={selectedPlan}
                    onChange={(e) => setSelectedPlan(e.target.value as PlanType)}
                    className="w-64 p-2 text-gray-700 bg-white border border-gray-300 text-xs rounded-md shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500"
                >
                    <option value="" disabled>
                        -- Choose an option --
                    </option>
                    <option value="FREE">Free</option>
                    <option value="OSD">OSD</option>
                    <option value="SUBSCRIBER">Subscriber</option>
                </select>
                <Button className="mt-2" loading={loading} disabled={loading} onClick={() => handleSubmit()}
                    title="Atualizar" />
            </div>

        </BaseModal>
    )
}
