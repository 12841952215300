import { PresentationChartBarIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useGoals } from "../hooks/useGoals";
import { formatToBrazilianCurrency } from "../utils/handleMoneyChange";
import CourseProgress from "./CourseProgress";

export default function GoalSummary() {
    const { data: goals } = useGoals({ page_size: 4 });
    const navigate = useNavigate();
    const hasMoreGoals = !!goals?.next;
    return <div className="flex flex-col border border-gray-300 rounded-xl w-full sm:w-1/2 flex-grow items-center">
        <div className="flex items-center px-4 py-2 gap-3 bg-gray-100 w-full border-b border-gray-300 rounded-t-xl">
            <PresentationChartBarIcon className="h-6 w-6 text-indigo-700" />
            <h3 className="text-sm font-semibold leading-7">
                Metas
            </h3>
        </div>
        <div className="bg-white w-full h-full px-4 py-1 rounded-b-xl">
            {goals?.results?.length === 0 && <div className="flex py-5 h-full flex-col items-center justify-center gap-1">
                <h3 className="font-semibold text-sm">Nenhuma meta</h3>
                <h3 className="text-sm text-gray-600">Adicione suas metas para acompanhamento</h3>
                <h4 onClick={() => navigate("/metas")} className="mt-2 text-sm cursor-pointer hover:bg-indigo-800 text-white py-1 px-4 rounded-md bg-indigo-700">Adicionar Metas</h4>
            </div>}
            {goals?.results?.map((goal, idx) => <div key={goal.id} className={`flex py-1 flex-col ${idx + 1 !== goals?.results?.length ? "border-b border-gray-300" : ""}`}>
                <div className="flex flex-row gap-2 justify-between items-center">
                    <h4 className="text-sm leading-7 font-light truncate">{goal.name}</h4>
                    <span className="text-sm leading-7 font-light">R${formatToBrazilianCurrency(`${goal.goal_amount}`)}</span>
                </div>
                <CourseProgress progress={{
                    completed: goal.amount,
                    total: goal.goal_amount,
                    is_completed: goal.progress.is_completed,
                }} />
            </div>)}
            {hasMoreGoals && <div className="flex justify-center p-2">
                <h4 onClick={() => navigate("/metas")} className="text-sm cursor-pointer hover:underline text-indigo-700">Ver todas {goals?.count} metas</h4>
            </div>}
        </div>
    </div>
}