import { CalendarIcon } from "@heroicons/react/20/solid";
import { format, parseISO } from "date-fns";
import React from "react";

type DateBadgeProps = {
    date: string | null;
    setDate: (date: string) => void;
}
export default function DateBadge({ date, setDate }: DateBadgeProps) {
    const isToday = date === format(new Date(), "yyyy-MM-dd");
    const isYesterday = date === format(new Date(new Date().setDate(new Date().getDate() - 1)), "yyyy-MM-dd");
    const isOtherDay = !!date && !isToday && !isYesterday;

    const dateInputRef = React.useRef<HTMLInputElement | null>(null);

    const openDatePicker = () => {
        if (dateInputRef.current) {
            dateInputRef.current.showPicker(); // Opens the native date picker
        }
    };

    return <div className="flex flex-row items-center gap-1">
        <Badge title="Hoje" selected={isToday} onClick={() => setDate(format(new Date(), "yyyy-MM-dd"))} />
        <Badge title="Ontem" selected={isYesterday} onClick={() => setDate(format(new Date(new Date().setDate(new Date().getDate() - 1)), "yyyy-MM-dd"))} />
        <Badge title={<label
            htmlFor="date-select" className="flex flex-row items-center gap-1 cursor-pointer">
            <CalendarIcon className={`min-h-3 min-w-3 h-3 w-3 ${isOtherDay ? "text-blue-700" : "text-gray-700"}`} />
            <h4 className={`text-xs text-nowrap ${isOtherDay ? "text-blue-700" : "text-gray-700"}`}>{isOtherDay ? format(parseISO(date), "dd/MM/yyyy") : "Selecionar data"}</h4>
        </label>} selected={isOtherDay} onClick={() => openDatePicker()} />
        <input
            id="date-select"
            type="date"
            ref={dateInputRef}
            onChange={(e) => setDate(e.target.value)}
            className="opacity-0 w-0 h-0"
        />
    </div>
}

function Badge({ title, selected, onClick }: { title: string | React.ReactNode; selected: boolean; onClick: () => void }) {
    return <div onClick={() => onClick()} className={`flex flex-row w-fit rounded-2xl border cursor-pointer justify-center ${selected ? "border-blue-700 bg-blue-50" : "border-gray-300 bg-gray-50"}`}>
        <h4 className={`text-xs ${selected ? "text-blue-700 font-semibold" : "text-gray-700"} py-1 px-3`}>{title}</h4>
    </div>
}