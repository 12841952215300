import { LockClosedIcon, PlayIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { useUser } from "../hooks/useUsers";
import { Course } from "../models/types";
import { BaseBadge } from "./Badge";

type CourseCardProps = {
    course: Course;
}
export default function CourseCard({ course }: CourseCardProps) {
    const navigate = useNavigate();
    const { data: user } = useUser();
    const isAdmin = user?.is_admin === true;
    const isSubscriber = user?.plan && user?.plan !== "FREE";
    const isOsdPlan = user?.plan === "OSD";
    const isOsdCourse = course.plan === "OSD";
    const isFreeCourse = course.plan === "FREE";
    const isSubscriberCourse = course.plan === "SUBSCRIBER";
    const { setPaymentModal } = useAppContext();

    const handleNavigate = () => {
        navigate(`/curso/${course.id}`)
    }

    const getActionButton = () => {
        if ((!course.free && !isSubscriber) || (isSubscriberCourse && isOsdPlan)) return <div onClick={() => setPaymentModal(true)}
            className="relative -mr-px inline-flex w-0 flex-1 items-center hover:bg-slate-50 cursor-pointer justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
        >
            <LockClosedIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
            Conteúdo Exclusivo
        </div>

        if (course.visible && (isOsdCourse || isFreeCourse)) return <div onClick={() => handleNavigate()}
            className="cursor-pointer hover:bg-slate-50 relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
        >
            <PlayIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
            Assistir Curso
        </div>
        if (course.visible && isSubscriberCourse && !isOsdPlan) return <div onClick={() => handleNavigate()}
            className="cursor-pointer hover:bg-slate-50 relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
        >
            <PlayIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
            Assistir Curso
        </div>
        return <div onClick={() => isAdmin && handleNavigate()}
            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
        >
            <LockClosedIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
            Em breve
        </div>
    }

    return <div className="col-span-1 flex flex-col max-w-72 min-w-72 divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
        <div className="flex flex-1 flex-col p-8">
            <img alt="" src={course?.image ?? "/images/no-image.png"} className="mx-auto h-32 w-32 flex-shrink-0 rounded-full" />
            <h3 className="mt-6 text-sm font-medium text-gray-900">{course.title}</h3>
            <dl className="mt-1 flex flex-grow flex-col justify-between">
                <dt className="sr-only">Role</dt>

                <dd className="mt-3 flex justify-center flex-grow">
                    {!course.visible && <BaseBadge color="text-red-900" title="Em breve" />}
                    {course.visible && course.progress.is_completed && <BaseBadge color="text-green-900" title="Finalizado" />}
                    {course.visible && !course.progress.is_completed && course.progress.completed === 0 && <BaseBadge title={`${course.progress.total} Aulas`} />}
                    {course.visible && !course.progress.is_completed && course.progress.completed > 0 && <BaseBadge title={`${course.progress.completed}/${course.progress.total} Aulas`} />}
                </dd>
            </dl>
        </div>
        <div>
            <div className="-mt-px flex divide-x divide-gray-200">
                <div className="flex w-0 flex-1">
                    {getActionButton()}
                </div>
            </div>
        </div>
    </div>
}