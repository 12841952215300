import queryClient, { QueryKey } from "../hooks/state";
import { PaymentIntentResponse, PlanType, User, UserFetchParams, UserSummaryData } from "../models/types";
import { PaginatedResponse } from './../models/types';
import { httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./Api";

const userEndpoints = {
  user: () => "/central/central/users",
  admin: () => "/admin/users",
  magic_link: () => "/admin/magic_link/send"
};

export const getAllUsers = async (params: UserFetchParams) => {
  return httpGetAuthenticated<PaginatedResponse<User>>(userEndpoints.admin(), { params });
}

export const getUserSummary = async (params: { id: number }) => {
  return httpGetAuthenticated<UserSummaryData>(userEndpoints.admin() + `/${params.id}/summary`);
}

export const updateUserPlan = async (userId: number, params: {
  plan: PlanType;
}) => {
  const { data } = await httpPatchAuthenticated(userEndpoints.admin() + `/${userId}`, params);
  queryClient.invalidateQueries(QueryKey.users);
  return data;
}

export const getUser = async () => {
  return httpGetAuthenticated<User>(userEndpoints.user());
}

export const getCompleteTutorial = async () => {
  const { data } = await httpGetAuthenticated<any>(userEndpoints.user() + "/complete_tutorial");
  queryClient.invalidateQueries(QueryKey.users);
}

export const getFormCompleted = async () => {
  const response = await httpGetAuthenticated<any>(userEndpoints.user() + "/get_form_completed");
  queryClient.invalidateQueries(QueryKey.users);
  return response;
}

export const getPaymentIntent = async () => {
  return httpGetAuthenticated<PaymentIntentResponse>(userEndpoints.user() + "/payment_intent");
}

export const sendMagicLink = async (body: { user: string }) => {
  return httpPostAuthenticated<any>(userEndpoints.magic_link(), body);
}