import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { config } from "../../config/env";
import { useAppContext } from "../../context/AppContext";
import { useUser } from "../../hooks/useUsers";
import { Button } from "../Button";
import BaseModal from "./BaseModal";

type PaymentModalProps = {

}

export default function PaymentModal({ }: PaymentModalProps) {
    const { paymentModal, setPaymentModal } = useAppContext();
    const { data: user } = useUser();
    const isOsd = user?.plan && user?.plan === "OSD";
    return (
        <BaseModal open={paymentModal} onClose={() => setPaymentModal(false)}>
            <div className="flex w-full flex-col gap-1">
                {isOsd && <>
                    <h3 className="text-md md:text-lg font-semibold text-center">Desbloqueie benefícios exclusivos</h3>
                    <h3 className="text-sm md:text-xs text-gray-500 mt-1 text-center">O recurso que você está tentando acessar não está incluso em sua assinatura, entre em contato com nosso time para consultar como alterar seu plano.</h3>
                    <Button className="mt-3 py-3 bg-green-600 hover:bg-green-700" onClick={() => window.open("https://wa.me/5511993581886?text=Ol%C3%A1,%20gostaria%20de%20alterar%20meu%20plano", "_blank")} title="Fale conosco no WhatsApp" />
                </>}
                {!isOsd && <>
                    <h3 className="text-md md:text-lg font-semibold text-center">Torne-se um membro e desbloqueie<br /> benefícios exclusivos</h3>
                    <div className="flex flex-row px-0 md:px-3 items-center gap-1 mt-3">
                        <CheckCircleIcon className="h-6 w-6 min-h-6 min-w-6 md:min-h-4 md:min-w-4 md:h-4 md:w-4 text-green-800" />
                        <h3 className="text-sm md:text-sm font-light text-gray-700">Ferramenta para controle e acompanhamento de metas</h3>
                    </div>
                    <div className="flex flex-row px-0 md:px-3 items-center gap-1">
                        <CheckCircleIcon className="h-6 w-6 md:h-4 md:w-4 text-green-800" />
                        <h3 className="text-sm md:text-sm font-light text-gray-700">Ferramenta para montar orçamento mensal</h3>
                    </div>
                    <div className="flex flex-row px-0 md:px-3 items-center gap-1">
                        <CheckCircleIcon className="h-6 w-6 md:h-4 md:w-4 text-green-800" />
                        <h3 className="text-sm md:text-sm font-light text-gray-700">Ferramenta para anotar todas suas despesas</h3>
                    </div>
                    <div className="flex flex-row px-0 md:px-3 items-center gap-1">
                        <CheckCircleIcon className="h-6 w-6 md:h-4 md:w-4 text-green-800" />
                        <h3 className="text-sm md:text-sm font-light text-gray-700">Trilha para utilização da plataforma</h3>
                    </div>
                    <div className="flex flex-row px-0 md:px-3 items-center gap-1">
                        <CheckCircleIcon className="h-6 w-6 md:h-4 md:w-4 text-green-800" />
                        <h3 className="text-sm md:text-sm font-light text-gray-700">Materiais bônus</h3>
                    </div>
                    <div className="flex flex-row px-0 md:px-3 items-center gap-1">
                        <CheckCircleIcon className="h-6 w-6 md:h-4 md:w-4 text-green-800" />
                        <h3 className="text-sm md:text-sm font-light text-gray-700">Suporte via WhatsApp</h3>
                    </div>
                    <div className="flex flex-row px-0 md:px-3 items-center gap-1">
                        <CheckCircleIcon className="h-6 w-6 md:h-4 md:w-4 text-green-800" />
                        <h3 className="text-sm md:text-sm font-light text-gray-700">1 ano de acesso</h3>
                    </div>
                    <h3 className="text-sm mt-3 text-gray-700 justify-center text-center items-center flex flex-col md:flex-row md:gap-[3px]">Tudo isso por apenas <p className="text-lg font-semibold text-indigo-900">R$97</p> ou até <p className="text-sm font-semibold text-indigo-900">12x de R$9,74</p></h3>
                    <Button className="mt-3 py-3" onClick={() => window.open(`${config.payment.kiwify_checkout_url}?email=${user?.email}&name=${user?.first_name + " " + user?.last_name}`, "_blank")} title="Comprar agora" />
                </>}
                <Button className="mt-1 text-xs bg-transparent text-gray-500 underline hover:no-underline hover:bg-transparent" title="Fechar" onClick={() => setPaymentModal(false)} />
            </div>

        </BaseModal>
    )
}
