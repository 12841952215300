import { WifiIcon } from "@heroicons/react/24/outline";
import { CreditCard } from "../models/types";
import { formatBrazilianCurrence } from "../utils/helper";

export default function CreditCardComponent({ card, onClick }: { card: CreditCard, onClick?: () => void }) {

    return <div onClick={() => onClick && onClick()} className={`${onClick ? "cursor-pointer" : ""} shadow-md w-60 max-w-60 px-4 ${card.color === "#c0c0c0" ? "text-black" : "text-white"} h-36 rounded-lg flex flex-col items-start truncate`} style={{ backgroundColor: card.color }}>
        <h3 className="text-sm pt-2 max-w-full font-semibold truncate">{card.name}</h3>
        <div className="flex w-full justify-between items-center">
            <div className="bg-gray-600 w-7 h-5 my-3 rounded-sm">
            </div>
            <WifiIcon className={`h-5 w-5 ${card.color === "#c0c0c0" ? "text-black" : "text-white"} transform rotate-90`} />
        </div>
        <h3 className="text-sm font-mono">0000 0000 0000 0000</h3>
        <div className="flex flex-col justify-end flex-grow pb-2">
            {card.limit && <h3 className="font-mono text-[10px] pt-1">Limite: {formatBrazilianCurrence(card.limit)}</h3>}
            {!card.limit && <h3 className="font-mono text-[10px] pt-1">Limite: Sem limite definido</h3>}
        </div>
    </div>
}