import { useQuery } from "react-query";
import { CreditCardFetchParams, CreditCardSummaryFetchParams, CreditCardTransactionFetchParams } from "../models/types";
import { getCreditCard, getCreditCards } from "../services/CreditCardService";
import { getCreditCardTransaction, getCreditCardTransactions, getCreditCardTransactionSummary } from "../services/CreditCardTransactions";
import { QueryKey } from "./state";

export const useCreditCard = (objectId: string | null) => {
    return useQuery([QueryKey.creditCard, objectId], async () => {
        if (!objectId || (objectId && objectId === "")) return null;
        const { data } = await getCreditCard(objectId);
        return data;
    });
}

export const useCreditCards = (params: CreditCardFetchParams) => {
    return useQuery([QueryKey.creditCard, params], async () => {
        const { data } = await getCreditCards(params);
        return data;
    });
}

export const useCreditCardTransaction = (objectId: string | null) => {
    return useQuery([QueryKey.creditCardTransaction, objectId], async () => {
        if (!objectId || (objectId && objectId === "")) return null;
        const { data } = await getCreditCardTransaction(objectId);
        return data;
    });
}

export const useCreditCardTransactions = (params: CreditCardTransactionFetchParams) => {
    return useQuery([QueryKey.creditCardTransaction, params], async () => {
        const { data } = await getCreditCardTransactions(params);
        return data;
    });
}

export const useCreditCardTransactionsSummary = (params: CreditCardSummaryFetchParams) => {
    return useQuery([QueryKey.creditCardTransactionSummary, params], async () => {
        const { data } = await getCreditCardTransactionSummary(params);
        return data;
    });
}