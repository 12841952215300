import { useCreditCards } from "../hooks/useCreditCards";
import { CreditCard, CreditCardFetchParams } from "../models/types";

type CreditCardDropdownProps = {
    selected: Pick<CreditCard, 'id' | 'name' | 'color'> | null;
    params: CreditCardFetchParams;
    onChange: (id: Pick<CreditCard, 'id' | 'name' | 'color'> | null) => void;
    error?: boolean;
    disabled?: boolean;
}

export default function CreditCardDropdown({ selected, onChange, params, error, disabled }: CreditCardDropdownProps) {
    const { data: cards } = useCreditCards(params);
    return <div className="flex flex-col">
        <select disabled={disabled} className={`text-xs py-2 px-4 rounded-2xl ${error ? "border-red-600" : ""}`} value={selected?.id ?? ""} onChange={(e) => onChange(cards?.results.find((c) => c.id === e.target.value) ?? null)}>
            <option key={""} value={""} disabled>Nenhum cartão</option>
            {cards?.results?.map((obj) => (
                <option key={obj.id} value={obj.id}>{obj.name}</option>
            ))}
        </select>
        {error && <h4 className="text-xs py-2 px-4 font-light text-red-600">Selecione um cartão</h4>}
    </div>
}