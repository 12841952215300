import { XMarkIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { useCreditCard } from "../../hooks/useCreditCards";
import { createCreditCard, deleteCreditCard, updateCreditCard } from "../../services/CreditCardService";
import { handleMoneyChange, handleNumberChange } from "../../utils/handleMoneyChange";
import { Button } from "../Button";
import ColorPicker from "../ColorPicker";
import CreditCardComponent from "../CreditCard";
import MainInput from "../MainInput";
import BaseModal from "./BaseModal";
import ConfirmationModal from "./ConfirmationModal";

type CreditCardModalProps = {
    open: boolean;
    onClose: () => void;
    cardId: string | null;
}



export default function CreditCardModal({ open, onClose, cardId }: CreditCardModalProps) {
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const [color, setColor] = useState("");
    const [colorError, setColorError] = useState("");

    const [paymentDay, setPaymentDay] = useState<number | null>(1);
    const [paymentDayError, setPaymentDayError] = useState("");
    const [closingDay, setClosingDay] = useState<number | null>(1);
    const [closingDayError, setClosingDayError] = useState("");

    const [limit, setLimit] = useState<string>("");

    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const { data: card } = useCreditCard(cardId);
    const [loading, setLoading] = useState(false);
    const { setNotification } = useAppContext();
    useEffect(() => {
        if (card) {
            setName(card.name);
            setPaymentDay(card.payment_day);
            setClosingDay(card.closing_day);
            setLimit(card.limit ? `${card.limit}` : "");
            setColor(card.color);
        } else {
            setName("");
            setColor("#000");
            setLimit("");
        }
    }, [card, open])

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (!name || (name && name === "")) {
                return setNameError("Informe um nome para o cartão");
            } else {
                setNameError("");
            }
            if (!color || (color && color === "")) {
                return setColorError("Informe uma cor para o cartão");
            } else {
                setColorError("");
            }
            if (!paymentDay || (paymentDay && paymentDay === 0)) {
                return setPaymentDayError("Informe um dia de pagamento");
            } else {
                setPaymentDayError("");
            }
            if (!closingDay || (closingDay && closingDay === 0)) {
                return setClosingDayError("Informe um dia de fechamento");
            } else {
                setClosingDayError("");
            }
            if (cardId) {
                await updateCreditCard(cardId, { name, ...(paymentDay && { payment_day: paymentDay }), ...(closingDay && { closing_day: closingDay }), limit: limit ? Number(limit.replace(/[,]/g, ".")) : null, color });
            } else {
                await createCreditCard({ name, payment_day: paymentDay, closing_day: closingDay, limit: limit ? Number(limit.replace(/[,]/g, ".")) : null, color });
            }
            onClose();
            setNotification({
                type: 'success',
                message: `Cartão ${cardId ? "editado" : "adicionado"} com sucesso`,
                title: `Cartões de Crédito`
            })
        } catch (error) {
            setNotification({
                type: 'error',
                message: `Erro ao ${cardId ? "editar" : "adicionar"} o cartão, tente novamente`,
                title: `Cartões de Crédito`
            })
        } finally {
            setLoading(false);
        }
    }

    const handleDelete = async () => {
        try {
            setLoading(true);
            if (cardId) {
                await deleteCreditCard(cardId);
                onClose();
                setNotification({
                    message: "Cartão de crédito excluido com sucesso",
                    title: "Sucesso",
                    type: "success"
                })
            }
        } catch (e) {
            setNotification({
                message: "Erro ao excluir cartão de crédito",
                title: "Erro",
                type: "error"
            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <BaseModal open={open} onClose={onClose}>
            <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                    <h2 className="text-sm font-semibold">{cardId ? "Editar cartão de crédito" : "Adicionar cartão de crédito"}</h2>
                    <div className="flex items-center gap-2">
                        {cardId && <span onClick={() => setOpenConfirmation(true)} className={`cursor-pointer inline-flex items-center bg-red-300 gap-x-1.5 rounded-md px-2 py-1 text-xs font-semibold text-red-900 ring-inset ring-gray-200 hover:ring-gray-300 hover:bg-red-400 hover:text-white`}>
                            Excluir
                        </span>}
                        <XMarkIcon onClick={() => onClose()} className="h-5 w-5 cursor-pointer" />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row gap-2 justify-between">
                    <div className="flex flex-col gap-4 flex-grow">
                        <MainInput value={name} onChange={(e) => setName(e.target.value)} error={nameError} title="Nome do cartão" />
                        <div className="flex flex-col gap-1">
                            <h3 className="text-xs font-semibold text-gray-900">Cor do cartão</h3>
                            <ColorPicker color={color} onChange={setColor} />

                        </div>
                        <MainInput value={`${limit}`} onChange={(e) => {
                            handleMoneyChange(e, setLimit)

                        }} error={""} title="Limite do cartão" />
                        <MainInput value={`${closingDay}`} onChange={(e) => {
                            handleNumberChange(e, setClosingDay, 31, 1)

                        }} error={closingDayError} title="Dia de fechamento" />
                        <MainInput value={`${paymentDay}`} onChange={(e) => {
                            handleNumberChange(e, setPaymentDay, 31, 1)

                        }} error={paymentDayError} title="Dia de pagamento" />
                    </div>
                    <CreditCardComponent card={{
                        id: "",
                        name,
                        color,
                        limit: limit ? Number(limit.replace(/[,]/g, ".")) : null,
                        payment_day: paymentDay ?? 0,
                        closing_day: closingDay ?? 0,
                        created_at: "",
                        updated_at: "",
                        user: 0,
                    }} />
                </div>
                <Button onClick={() => handleSubmit()}
                    disabled={loading}
                    className="py-2 rounded-sm"
                    loading={loading} title={cardId ? "Salvar Alterações" : "Adicionar Cartão"} />
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    onConfirm={() => handleDelete()}
                    onCancel={() => setOpenConfirmation(false)}
                    title="Excluir Cartão"
                    message={`Deseja realmente excluir o cartão ${card?.name}? Essa ação é irreversivel e todas transações desse cartão serão excluidas.`} />
            </div>
        </BaseModal>
    )
}
