import { useState } from "react";
import { useAdminUsers } from "../hooks/useUsers";
import { User } from "../models/types";
import { usersColumns } from "../pages/shared/UsersTable";
import UserSummaryModal from "./modals/UserSummaryModal";
import BaseTable from "./monthly-budget/Table";

export default function UsersAdmin() {
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    return <>
        {selectedUser && <UserSummaryModal open={!!selectedUser} onClose={() => setSelectedUser(null)} userData={selectedUser} />}
        <BaseTable
            title={null}
            onRowClick={(row) => {
                setSelectedUser(row);
            }}
            columns={usersColumns}
            useGetData={useAdminUsers}
        />
    </>
}