import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { formatBrazilianCurrence } from "../utils/helper";
import ExpenseSummary from "./ExpensesSummary";
import PaywallButton from "./PaywallButton";
import TitleCard from "./TitleCard";

export default function ExpenseSummaryPaywall() {
    const currentDate = new Date();
    const get_current_date = () => format(currentDate, "MMMM/yyyy", { locale: ptBR })
    return <TitleCard title={`Transações de ${get_current_date()}`}>
        <div className="flex w-full flex-col">
            <div className="flex w-full opacity-20">
                <ExpenseSummary loading={false} values={[
                    { label: "Despesa Projetada", value: `R$ ${formatBrazilianCurrence(10000)}`, className: "text-red-400" },
                    { label: "Pagamento Pendente", value: `R$ ${formatBrazilianCurrence(1860)}`, className: "text-gray-400" },
                    { label: "Vencido", value: `R$ ${formatBrazilianCurrence(0)}`, className: "text-red-400" },
                    { label: "Pago", value: `R$ ${formatBrazilianCurrence(8140)}`, className: "text-green-400" },
                ]} />
            </div>
            <PaywallButton lockRow className="h-9 w-9 md:h-9 md:w-9 mt-[-300px] md:mt-[-60px] mr-2" />
        </div>
    </TitleCard>
}