import { InformationCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import { ImportTransactionType, TransactionCategory } from "../models/types";
import CategoryDropdown from "./CategoryDropdown";
import ConfirmationModal from "./modals/ConfirmationModal";

type ImportTransactionProps = {
    row: ImportTransactionType;
    onChange: (row: ImportTransactionType, fieldKey: string, value: any) => void;
    onRemove: (row: ImportTransactionType) => void;
}
export default function ImportTransaction({ row, onChange, onRemove }: ImportTransactionProps) {
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const transactionCategoryType: "income" | "expense" = row.type === "credit" ? "income" : "expense";
    return <div className="flex flex-col md:flex-row items-start md:items-center gap-3 py-3 md:py-2">
        <div className="flex flex-col flex-grow gap-1 w-full">
            <div className="flex flex-row gap-1 justify-between">
                <div className="flex flex-row gap-1">
                    <h4 className="text-xs font-semibold">{format(parseISO(row.payment_date), "dd/MM/yyyy")} - </h4>
                    <h4 className={`text-xs font-semibold ${row.type === "credit" ? "text-green-600" : "text-red-600"}`}>{row.type === "credit" ? "Receita" : "Despesa"}</h4>
                </div>
                <TrashIcon onClick={() => setOpenConfirmation(true)} className="block md:hidden min-h-4 min-w-4 h-4 w-4 cursor-pointer text-red-600" />
            </div>
            <input
                type="text"
                name="amount"
                id="amount"
                placeholder="Valor em R$"
                value={row.name}
                onChange={(e) => onChange(row, "name", e.target.value)}
                className="p-0 text-xs border-none focus:ring-0 focus:outline-none focus:border-b-2 focus:underline cursor-pointer focus:border-blue-500"
            />
            {row.exists && <div className="flex flex-row gap-1 items-center">
                <div className="relative group">
                    <InformationCircleIcon onMouseEnter={() => setIsTooltipVisible(true)}
                        onMouseLeave={() => setIsTooltipVisible(false)} className="h-5 w-5 text-yellow-600" />
                    {isTooltipVisible && <div
                        className="absolute z-50 left-0 mt-2 w-60 p-2 bg-gray-800 text-white text-xs rounded shadow-lg opacity-0 transition-opacity duration-300 group-hover:opacity-100 text-wrap"
                    >
                        Identificamos que essa transação já foi importada para o sistema previamente, se não for removida ela será duplicada no sistema.
                    </div>}
                </div>
                <h3 className="text-xs text-yellow-600">Transação duplicada</h3></div>}
        </div>
        <div className="flex flex-col gap-1 min-w-60 md:max-w-60">
            <h4 className="text-xs font-semibold">Categoria</h4>
            <CategoryDropdown params={{ page_size: 100, category_type: transactionCategoryType }} selected={row.category} onChange={(category: Pick<TransactionCategory, 'id' | 'name'> | null) => {
                onChange(row, "category", category)
            }} />
        </div>
        <div className="flex flex-col gap-1 min-w-40 md:max-w-40">
            <h4 className="text-xs font-semibold">Valor</h4>
            <div className="flex flex-row items-center">
                <h3 className="text-xs">R$</h3>
                <input
                    type="text"
                    name="amount"
                    id="amount"
                    placeholder="Valor em R$"
                    value={row.amount}
                    onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericInput = inputValue.replace(/[^0-9,]/g, '');
                        const splitted = numericInput.split(',');
                        if (splitted.length > 2) return;
                        if (splitted.length === 2 && splitted[1].length > 2) return;
                        onChange(row, "amount", numericInput)
                    }}
                    className="pl-1 p-0 text-xs border-none focus:ring-0 focus:outline-none focus:border-b-2 focus:underline cursor-pointer focus:border-blue-500"

                />
            </div>
        </div>
        <TrashIcon onClick={() => setOpenConfirmation(true)} className="hidden md:block min-h-4 min-w-4 h-4 w-4 cursor-pointer text-red-600" />
        <ConfirmationModal open={openConfirmation} onClose={() => setOpenConfirmation(false)} onCancel={() => setOpenConfirmation(false)}
            onConfirm={() => {
                onRemove(row);
                setOpenConfirmation(false);
            }} title="Remover transação" message={`Tem certeado que deseja remover a transação '${row.name}'?`} />
    </div>
}