import ImportTransaction from "../../components/ImportTransaction";
import { ImportTransactionType, TableColumn } from "../../models/types";

export const importTransactionsColumns = (onChange: (row: ImportTransactionType, fieldKey: string, value: any) => void, onRemove: (row: ImportTransactionType) => void): TableColumn<ImportTransactionType>[] => [
    {
        field: "name",
        header: "Transação",
        className: "w-full",
        render: (t) => <ImportTransaction row={t} onChange={onChange} onRemove={onRemove} />,
    },
]