import { useEffect, useState } from "react";
import { useCreditCards } from "../../hooks/useCreditCards";
import { CreditCard, CreditCardFetchParams, CreditCardSummaryFetchParams } from "../../models/types";
import CustomSelectDropdown from "./CustomSelectDropdown";

type CreditCardFiltersProps = {
    filters: CreditCardSummaryFetchParams;
    setFilters: (filters: CreditCardSummaryFetchParams) => void;
}
export const CreditCardFilters = ({ filters, setFilters }: CreditCardFiltersProps) => {
    const [selectedCard, setSelectedCard] = useState<Pick<CreditCard, 'id' | 'name' | 'color'> | null>(null);

    useEffect(() => {
        if (selectedCard) {
            setFilters({
                credit_card: selectedCard.id
            })
        } else {
            setFilters({})
        }
    }, [selectedCard])

    return <div className="flex flex-col flex-grow md:flex-row gap-2 w-full items-start md:items-center">
        {/* <CreditCardDropdown onChange={setSelectedCard} params={{}} selected={selectedCard} /> */}
        <CustomSelectDropdown<Pick<CreditCard, 'id' | 'name' | 'color'>, CreditCardFetchParams>
            params={{ page_size: 100 }}
            placeholder="Selecione um cartão"
            label="Cartão"
            useGetOptions={useCreditCards}
            nameKey="name" onChange={setSelectedCard} selected={selectedCard}
            className="w-full md:max-w-xs"
        />
    </div>
}