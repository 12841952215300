import { format, parseISO } from "date-fns";
import PlanBadge from "../../components/badges/PlanBadge";
import { TableColumn, User } from "../../models/types";

export const usersColumns: TableColumn<User>[] = [
    {
        field: "name",
        header: "Name",
        render: (t) => <h4 className="text-sm text-gray-500">{t.name}</h4>,
    },
    {
        field: "email",
        header: "E-mail",
        render: (t) => <h4 className="text-sm text-gray-500">{t.email}</h4>,
    },
    {
        field: "phone",
        header: "Telefone",
        render: (t) => <h4 className="text-sm text-gray-500">{t.phone}</h4>,
    },
    {
        field: "origin",
        header: "Origem",
        render: (t) => <h3 className="text-sm text-gray-500">{t.origin}</h3>,
    },
    {
        field: "created_at",
        header: "Criado em",
        render: (t) => <h4 className="text-sm text-gray-500">{t.created_at ? format(parseISO(t.created_at), "dd/MM/yyyy HH:mm") : ""}</h4>,
    },
    {
        field: "plan",
        header: "Plan",
        render: (t) => <PlanBadge plan={t.plan} />
    }
]