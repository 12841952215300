import { TagIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { useGroupedMonthlyTransactionsSummary } from "../hooks/useMonthlyTransactions";
import { MonthSummaryData } from "../models/types";
import { formatBrazilianCurrence } from "../utils/helper";
import CourseProgress from "./CourseProgress";
import GroupedBudgetSkeletonLoader from "./skeleton/GroupedBudgetSkeletonLoader";
import TitleCard from "./TitleCard";

export default function StackedSummary() {
    const { data: groupedSummary, isLoading } = useGroupedMonthlyTransactionsSummary({ month: format(new Date(), "yyyy-MM-dd") });
    return groupedSummary && groupedSummary?.count === 0 ? null : <TitleCard title={`Orçamento do Mês`} titleBoxClassName="md:flex-col md:items-start bg-indigo-900" subtitle={`Lembre-se de manter suas transações atualizadas para que os valores aqui exibidos reflitam os dados mais recentes`}><div className="flex flex-col w-full">
        {isLoading && <GroupedBudgetSkeletonLoader />}
        {!isLoading && groupedSummary?.results.map((value, index) => <CategorySummary key={index} value={value} isLast={index === groupedSummary.results.length - 1} />)}
    </div></TitleCard>
}

export function CategorySummary({ value, isLast }: { value: MonthSummaryData, isLast: boolean }) {
    const available = (value.budget_amount - value.total_amount) < 0 ? 0 : (value.budget_amount - value.total_amount);
    return <div className={`flex flex-col md:flex-row w-full gap-3 md:gap-0 py-1 items-start md:items-center justify-between ${isLast ? "" : "border-b border-gray-200"}`}>
        <div className="flex flex-row gap-2 items-center">
            <div className="rounded-full h-7 w-7 bg-gray-200 flex items-center justify-center">
                <TagIcon className="h-4 w-4 text-gray-500" />
            </div>
            <div className="flex flex-col">
                <h3 className="text-sm text-gray-700">{value.name}</h3>
                <h3 className="text-xs font-light text-gray-400">Restam R$ {formatBrazilianCurrence((available).toFixed(2))}</h3>
            </div>
        </div>
        <div className="flex flex-col justify-center items-end gap-1 min-w-full md:min-w-48">
            <CourseProgress hideText progress={{
                completed: value.total_amount,
                total: value.budget_amount,
                is_completed: available === 0,
            }} />
            <h3 className="text-xs font-light text-gray-400"> R$ {formatBrazilianCurrence((value.total_amount).toFixed(2))} de R$ {formatBrazilianCurrence((value.budget_amount).toFixed(2))}</h3>
        </div>
    </div>
}