import { format, isBefore, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { useMonthlyTransaction } from "../../hooks/useMonthlyTransactions";
import { useTransactionCategories } from "../../hooks/useTransactionCategories";
import { TransactionCategory, TransactionCategoryFilterParams } from "../../models/types";
import { createMonthlyTransaction, deleteMonthlyTransaction, updateMonthlyTransaction } from "../../services/MonthlyTransactionService";
import { handleMoneyChange } from "../../utils/handleMoneyChange";
import { CategoryBadge } from "../Badge";
import { Button } from "../Button";
import DateBadge from "../DateBadge";
import CustomSelectDropdown from "../monthly-budget/CustomSelectDropdown";
import ConfirmationModal from "./ConfirmationModal";
import SidebarModal from "./SidebarModal";

type BaseModalProps = {
    open: boolean;
    onClose: () => void;
    transactionId: string | null;
    isExpense: boolean;
}

export default function MonthlyTransactionModal({ open, onClose, transactionId, isExpense }: BaseModalProps) {
    const [expense, setExpense] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<TransactionCategory | null>(null);
    const [name, setName] = useState<string>("");
    const [amount, setAmount] = useState<string>("0");
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [amountError, setAmountError] = useState<boolean>(false);
    const [paid, setPaid] = useState<boolean>(false);
    const [dueDate, setDueDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
    const [paymentDate, setPaymentDate] = useState<string | null>(null);
    const [nameError, setNameError] = useState<boolean>(false);
    const [dueDateError, setDueDateError] = useState<boolean>(false);
    const [paymentDateError, setPaymentDateError] = useState<boolean>(false);
    const [categoryError, setCategoryError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const { setNotification } = useAppContext();

    const { data: transaction } = useMonthlyTransaction(transactionId);

    useEffect(() => {
        if (transactionId && transaction) {
            setName(transaction.name)
            setAmount(`${transaction.amount}`.replace(".", ","));
            setSelectedCategory(transaction.category_name)
            setPaid(transaction.paid)
            setDueDate(transaction.month)
            setPaymentDate(transaction.payment_date)
            setExpense(transaction.type === "debit")
        } else {
            setName("")
            setAmount("0");
            setSelectedCategory(null)
            setPaid(false)
            setDueDate(format(new Date(), "yyyy-MM-dd"))
            setPaymentDate(format(new Date(), "yyyy-MM-dd"))
            setExpense(isExpense)
        }
    }, [open, transaction])

    const handleCreate = async () => {
        try {

            setLoading(true);
            let hasError = false;
            if (!name || name === "") {
                setNameError(true);
                hasError = true;
            } else {
                setNameError(false);
            }
            if (!amount || amount === "0,00" || amount === "" || amount === "0") {
                setAmountError(true);
                hasError = true;
            } else {
                setAmountError(false);
            }
            if (!dueDate || (dueDate && dueDate === "")) {
                setDueDateError(true);
                hasError = true;
            } else {
                setDueDateError(false);
            }
            if (!selectedCategory) {
                setCategoryError(true);
                hasError = true;
            } else {
                setCategoryError(false);
            }

            if (hasError) return;

            if (transactionId) {
                await updateMonthlyTransaction(transactionId, {
                    amount: parseFloat(amount.replace(",", ".")),
                    category: selectedCategory!.id,
                    name,
                    paid,
                    payment_date: paymentDate !== "" ? paymentDate : null,
                    month: dueDate,
                    type: expense ? "debit" : "credit",

                })
            } else {
                const isPaid = paymentDate !== "" && paymentDate &&
                    isBefore(parseISO(paymentDate), new Date())
                await createMonthlyTransaction({
                    amount: parseFloat(amount.replace(",", ".")),
                    category: selectedCategory!.id,
                    name,
                    paid: isPaid ? true : false,
                    payment_date: paymentDate !== "" ? paymentDate : null,
                    month: dueDate,
                    type: expense ? "debit" : "credit",
                })
            }
            setNotification({
                type: 'success',
                message: `Transação ${transactionId ? "editada" : "adicionada"} com sucesso`,
                title: `Transação ${transactionId ? "editada" : "adicionada"}`
            })
            onClose();
        } catch (error) {
            setNotification({
                type: 'error',
                message: 'Tente novamente',
                title: 'Erro ao adicionar transação'
            })
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteTransaction = async () => {
        try {
            if (transactionId) {
                await deleteMonthlyTransaction(transactionId);
                setNotification({
                    type: 'success',
                    message: 'Transação excluida com sucesso',
                    title: 'Transação excluida'
                })
                onClose();
                setOpenConfirmation(false);
            } else {
                setNotification({
                    type: 'error',
                    message: 'Nenhuma transação selecionada',
                    title: 'Erro ao excluir transação'
                })
            }
        } catch (error) {
            setNotification({
                type: 'error',
                message: 'Tente novamente',
                title: 'Erro ao excluir transação'
            })
        }
    }

    return (
        <SidebarModal
            onDelete={transactionId ? () => setOpenConfirmation(true) : undefined}
            open={open}
            onClose={onClose}
            title={`${transactionId ? "Editar" : "Adicionar"} ${expense ? "Despesa" : "Receita"}`}
            action={<Button
                disabled={loading}
                loading={loading}
                onClick={() => handleCreate()}
                className="bg-blue-600 hover:bg-blue-700 w-full rounded-2xl text-white"
                title={transactionId ? "Salvar" : "Adicionar"}
            />}
        >
            <div className="flex flex-col gap-2 flex-grow mt-2">
                <h4 className="text-xs font-semibold text-gray-700">Valor</h4>
                <div className={`flex flex-row gap-1 items-center ${amountError ? "border-b border-red-600" : ""}`}>
                    <h4 className="text-sm font-semibold">R$</h4>
                    <input
                        type="text"
                        name="amount"
                        id="amount"
                        placeholder="0,00"
                        value={amount}
                        onFocus={(e) => {
                            if (e.target.value === "0,00") {
                                setAmount("");
                            }
                        }}
                        onChange={(e) => {
                            setAmountError(false);
                            handleMoneyChange(e, setAmount)
                        }}
                        className={`p-0 text-lg border-none font-semibold focus:ring-0 focus:outline-none focus:border-b-2 focus:underline cursor-pointer focus:border-blue-500`}
                    />
                </div>
                {amountError && <h4 className="px-2 text-xs font-light text-red-600">O valor é obrigatório</h4>}
                <h4 className="text-xs font-semibold text-gray-700">Título</h4>
                <input
                    type="text"
                    name="title"
                    id="title"
                    placeholder="Título da transação"
                    value={name}
                    onChange={(e) => {
                        setNameError(false);
                        setName(e.target.value)
                    }}
                    className={`py-2 px-4 rounded-2xl text-xs cursor-pointer ${nameError ? "border-red-600" : ""}`}
                />
                {nameError && <h4 className="px-4 text-xs font-light text-red-600">O título é obrigatório</h4>}
                <h4 className="text-xs font-semibold text-gray-700">Categoria</h4>
                <CustomSelectDropdown<TransactionCategory, TransactionCategoryFilterParams>
                    params={{ page_size: 100, category_type: expense ? "expense" : "income" }}
                    secondComponent={(category) => <CategoryBadge category={category} />}
                    useGetOptions={useTransactionCategories} nameKey="name" onChange={(v) => {
                        setSelectedCategory(v);
                        setCategoryError(false);
                    }} selected={selectedCategory} />
                {categoryError && <h4 className="px-4 text-xs font-light text-red-600">A categoria é obrigatória</h4>}
                <h4 className="text-xs font-semibold text-gray-700">Data de Vencimento</h4>
                <DateBadge date={dueDate} setDate={setDueDate} />
                {dueDateError && <h4 className="px-4 text-xs font-light text-red-600">A data de vencimento é obrigatória</h4>}
                <h4 className="text-xs font-semibold text-gray-700">Data de Pagamento</h4>
                <DateBadge date={paymentDate} setDate={setPaymentDate} />
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    onConfirm={() => handleDeleteTransaction()}
                    onCancel={() => setOpenConfirmation(false)}
                    title="Excluir Transação"
                    message={`Deseja realmente excluir a transação ${transaction?.name}`} />
            </div>
        </SidebarModal >
    )
}
