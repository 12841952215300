import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import { PlayCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { useCourseModule } from "../hooks/useCourseModules";
import { useCourseModuleClass, useCourseModuleClasses } from "../hooks/useCourseModulesClasses";
import { useCourse } from "../hooks/useCourses";
import { getCompleteClass } from "../services/CourseModuleClassService";
import { getNextClass } from "../services/CourseModuleService";
import BreadCrumb from "./BreadCrumb";
import { Button } from "./Button";
import LoadingSpinner from "./Loading";
import Video from "./Video";

/* eslint-disable jsx-a11y/iframe-has-title */
export const AulaComponent = () => {
    const { setNotification } = useAppContext();
    const { courseId, moduleId, classId } = useParams();
    const navigate = useNavigate();
    if (!courseId || !moduleId || !classId) {
        navigate("/inicio")
    }
    const [loading, setLoading] = useState<boolean>(false);

    const handleNext = async () => {
        try {
            setLoading(true);
            if (!classId || !moduleId) {
                throw new Error("")
            }
            await getCompleteClass(classId);
            const { data: nextClass } = await getNextClass(moduleId, classId, true);
            if (nextClass.class_id && nextClass.module_id) {
                window.location.href = `/curso/${courseId}/modulo/${nextClass.module_id}/aula/${nextClass.class_id}`;
            } else {
                navigate(`/curso/${courseId}/finalizado`);
                setNotification({
                    message: "Módulo finalizado com sucesso",
                    title: "Módulo Finalizado",
                    type: "success"
                })
            }
        } catch (err) {
            setNotification({
                message: "Erro ao carregar próxima aula",
                title: "Erro",
                type: "error"
            })
        } finally {
            setLoading(true);
        }
    }

    const { data: course, isLoading: loadingCourse } = useCourse(courseId ?? "");
    const { data: courseModule, isLoading: loadingModule } = useCourseModule(moduleId ?? "");
    const { data: moduleClasses, isLoading: loadingClasses } = useCourseModuleClasses({ module: moduleId ?? "" });
    const { data: courseModuleClass, isLoading: loadingClass } = useCourseModuleClass(classId ?? "");

    const isLoading = loadingCourse || loadingModule || loadingClass;
    return isLoading || !course || !courseModule || !courseModuleClass ? <LoadingSpinner /> : <div className="flex flex-col gap-y-4">
        <div className="flex w-full justify-between">
            <BreadCrumb pages={[
                { name: course.title, href: `/curso/${course.id}`, current: false },
                { name: courseModule.title, href: `/curso/${course.id}`, current: false },
                { name: courseModuleClass.title, href: `/curso/${course.id}/modulo/${courseModule.id}/aula/${classId}`, current: true },
            ]} />
        </div>
        <div className="flex flex-col lg:flex-row flex-grow gap-2">
            <Video onEnd={() => handleNext()} videoId={courseModuleClass.external_id} title={courseModuleClass.title} />
            <div className="hidden hover:cursor-pointer lg:flex flex-col flex-grow max-w-full lg:max-w-80 min-w-80 h-full rounded-md bg-white border border-gray-300 overflow-y-auto md:max-h-[750px] lg:min-h-[750px]" >
                {moduleClasses?.results?.map((c, index) => {
                    return <div key={index} onClick={() => {
                        window.location.href = `/curso/${courseId}/modulo/${moduleId}/aula/${c.id}`;
                    }} className={`flex flex-row truncate max-w-full items-center justify-between gap-2 py-4 px-2 border-b border-gray-300 hover:bg-gray-100 ${c.completed ? "bg-gray-50" : ""}`}>
                        <div className="flex flex-row items-center gap-2 truncate">
                            <div className="min-w-10 bg-gray-100 min-h-10 border rounded-sm border-gray-300 flex items-center justify-center">
                                <PlayCircleIcon className="h-6 w-6 text-gray-500" />
                            </div>
                            <div className={`flex flex-col max-w-max truncate ${c.completed ? "opacity-35" : ""}`}>
                                <h3 className="text-sm font-semibold truncate">{c.title}</h3>
                                <h3 className="text-xs font-light truncate">{c.description}</h3>
                            </div>
                        </div>
                        {c.completed && <CheckBadgeIcon className="min-h-5 min-w-5 h-5 w-5 text-green-500" />}
                    </div>
                })}
            </div>
        </div>

        <div className="flex w-full justify-between gap-10">
            <div className="flex flex-col gap-1 flex-grow">
                <div className="flex pb-2 lg:pb-0 flex-row items-center justify-between w-full">
                    <h4 className="text-md font-semibold">{courseModuleClass.title}</h4>
                    <Button className="lg:hidden" title="Próxima Aula" onClick={() => handleNext()} />
                </div>
                <h4 className="text-sm font-light font-gray-500 whitespace-pre-line">{courseModuleClass.description}</h4>
            </div>
            <Button onClick={() => handleNext()} className="hidden lg:flex h-10 min-w-80 rounded-md justify-center items-center" disabled={loading} title="Próxima Aula" />
        </div>
        <h4 className="flex lg:hidden text-md font-semibold">Aulas do módulo:</h4>
        <div className="lg:hidden hover:cursor-pointer flex flex-col flex-grow max-w-full lg:max-w-80 min-w-80 h-full rounded-md bg-white border border-gray-300 overflow-y-auto md:max-h-[750px] lg:min-h-[750px]" >
            {moduleClasses?.results?.map((c, index) => {
                return <div key={index} onClick={() => {
                    window.location.href = `/curso/${courseId}/modulo/${moduleId}/aula/${c.id}`;
                }} className={`flex flex-row truncate max-w-full items-center justify-between gap-2 py-4 px-2 border-b border-gray-300 hover:bg-gray-100 ${c.completed ? "bg-gray-50" : ""}`}>
                    <div className="flex flex-row items-center gap-2 truncate">
                        <div className="min-w-10 bg-gray-100 min-h-10 border rounded-sm border-gray-300 flex items-center justify-center">
                            <PlayCircleIcon className="h-6 w-6 text-gray-500" />
                        </div>
                        <div className={`flex flex-col max-w-max truncate ${c.completed ? "opacity-35" : ""}`}>
                            <h3 className="text-sm font-semibold truncate">{c.title}</h3>
                            <h3 className="text-xs font-light truncate">{c.description}</h3>
                        </div>
                    </div>
                    {c.completed && <CheckBadgeIcon className="min-h-5 min-w-5 h-5 w-5 text-green-500" />}
                </div>
            })}
        </div>
    </div>
}