import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { useTransactionCategories } from "../../hooks/useTransactionCategories";
import { useTransaction } from "../../hooks/useTransactions";
import { TransactionCategory, TransactionCategoryFilterParams } from "../../models/types";
import { createTransaction, deleteTransaction, updateTransaction } from "../../services/TransactionService";
import { handleMoneyChange } from "../../utils/handleMoneyChange";
import { CategoryBadge } from "../Badge";
import { CustomInput } from "../CustomInput";
import CustomSelectDropdown from "../monthly-budget/CustomSelectDropdown";
import BaseModal from "./BaseModal";
import ConfirmationModal from "./ConfirmationModal";

type BaseModalProps = {
    open: boolean;
    onClose: () => void;
    transactionId: string | null;
}
function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}

export default function TransactionModal({ open, onClose, transactionId }: BaseModalProps) {
    const [selectedCategory, setSelectedCategory] = useState<TransactionCategory | null>(null);
    const [name, setName] = useState<string>("");
    const [amount, setAmount] = useState<string>("0");
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [amountError, setAmountError] = useState<string>("");
    const [nameError, setNameError] = useState<string>("");
    const [categoryError, setCategoryError] = useState<string>("");
    const [dueDay, setDueDay] = useState<number>(1);
    const [dueDayError, setDueDayError] = useState<string>("");
    const { setNotification } = useAppContext();

    const { data: transaction } = useTransaction(transactionId);

    useEffect(() => {
        if (transactionId && transaction) {
            setName(transaction.name)
            setAmount(`${transaction.amount}`.replace(".", ","));
            setSelectedCategory(transaction.category_name)
            setDueDay(transaction.due_day)
        } else {
            setName("")
            setAmount("0");
            setSelectedCategory(null)
            setDueDay(1);
        }
    }, [open, transaction])

    const handleChangeDueDay = (val: string) => {
        // Remove all characters that are not numbers
        const value = val.replace(/\D/g, '');
        if (value.length > 2) {
            return;
        }
        const day = Number(value);
        setDueDay(day);
        if (day < 1 || day > 31) {
            setDueDayError("Dia inválido, insira um dia entre 1 e 31");
        } else {
            setDueDayError("");
        }
    }

    const handleCreate = async () => {
        try {
            if (selectedCategory === null) {
                setCategoryError("Selecione uma categoria");
                return;
            } else {
                setCategoryError("");
            }
            if (name === "") {
                setNameError("Nome da transação é obrigatório");
                return;
            } else {
                setNameError("");
            }
            if (dueDay < 1 || dueDay > 31) {
                setDueDayError("Dia inválido, insira um dia entre 1 e 31");
                return;
            } else {
                setDueDayError("");
            }
            if (amount === "") {
                setAmountError("Valor da transação é obrigatório");
                return;
            } else {
                setAmountError("");
            }
            if (transactionId) {
                await updateTransaction(transactionId, {
                    amount: parseFloat(amount.replace(",", ".")),
                    category: selectedCategory!.id,
                    name,
                    due_day: dueDay
                })
            } else {
                await createTransaction({
                    amount: parseFloat(amount.replace(",", ".")),
                    category: selectedCategory!.id,
                    name,
                    due_day: dueDay
                })
            }
            setNotification({
                type: 'success',
                message: `Transação ${transactionId ? "editada" : "adicionada"} com sucesso`,
                title: `Transação ${transactionId ? "editada" : "adicionada"}`
            })
            onClose();
        } catch (error) {
            setNotification({
                type: 'error',
                message: 'Tente novamente',
                title: 'Erro ao adicionar transação'
            })
        }
    }

    const handleDeleteTransaction = async () => {
        try {
            if (transactionId) {
                await deleteTransaction(transactionId);
                setNotification({
                    type: 'success',
                    message: 'Transação excluida com sucesso',
                    title: 'Transação excluida'
                })
                onClose();
                setOpenConfirmation(false);
            } else {
                setNotification({
                    type: 'error',
                    message: 'Nenhuma transação selecionada',
                    title: 'Erro ao excluir transação'
                })
            }
        } catch (error) {
            setNotification({
                type: 'error',
                message: 'Tente novamente',
                title: 'Erro ao excluir transação'
            })
        }
    }

    return (
        <BaseModal open={open} onClose={onClose}>
            <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">

                    <h2 className="text-lg font-semibold">{transactionId ? "Editar" : "Adicionar"} depesa fixa</h2>
                    <div className="flex items-center gap-2">
                        {transactionId && <span onClick={() => setOpenConfirmation(true)} className={`cursor-pointer inline-flex items-center bg-red-300 gap-x-1.5 rounded-md px-2 py-1 text-xs font-light text-red-900 ring-1 ring-inset ring-gray-200 hover:ring-gray-300 hover:bg-red-400 hover:text-white`}>
                            Excluir
                        </span>}
                        <XMarkIcon onClick={() => onClose()} className="h-5 w-5 cursor-pointer" />
                    </div>
                </div>
                <div className="flex flex-col">
                    <h4 className="block text-sm font-medium text-gray-900">Categoria</h4>
                    <h5 className="text-sm text-gray-500">Selecione a categoria que melhor descreve essa transação</h5>
                </div>
                <CustomSelectDropdown<TransactionCategory, TransactionCategoryFilterParams>
                    params={{ budget_amount_not_null: true, page_size: 100, category_type: "expense" }}
                    secondComponent={(category) => <CategoryBadge category={category} />}
                    useGetOptions={useTransactionCategories} nameKey="name" onChange={setSelectedCategory} selected={selectedCategory} />
                {categoryError !== "" && <p id="due-day-error" className="text-xs text-red-600">
                    {categoryError}
                </p>}
                <div className="flex flex-col">
                    <h4 className="block text-sm font-medium text-gray-900">Nome da transação</h4>
                    <h5 className="text-sm text-gray-500">Insira um nome de identificação para transação</h5>
                </div>
                <input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {nameError !== "" && <p id="due-day-error" className="text-xs text-red-600">
                    {nameError}
                </p>
                }
                <div className="flex flex-col">
                    <h4 className="block text-sm font-medium text-gray-900">Dia do vencimento</h4>
                    <h5 className="text-sm text-gray-500">Insira o dia de vencimento da transação (1 a 31)</h5>
                </div>
                <input
                    type="text"
                    name="dueday"
                    id="dueday"
                    value={dueDay}
                    onChange={(e) => handleChangeDueDay(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {dueDayError !== "" && <p id="due-day-error" className="text-xs text-red-600">
                    {dueDayError}
                </p>
                }
                <div className="flex flex-col">
                    <h4 className="block text-sm font-medium text-gray-900">Valor</h4>
                    <h5 className="text-sm text-gray-500">Informe o valor da transação</h5>
                </div>
                <CustomInput
                    bold
                    error={amountError}
                    onChange={(e) => {
                        handleMoneyChange(e, setAmount)
                        setAmountError("");
                    }}
                    value={amount}
                    placeholder="0.00"
                    fixedPlaceholder="R$"
                    id="valor"
                    type="text"
                />
                <button onClick={() => handleCreate()} className="bg-indigo-600 rounded-md text-white font-medium py-1">{transactionId ? "Atualizar" : "Adicionar"}</button>
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    onConfirm={() => handleDeleteTransaction()}
                    onCancel={() => setOpenConfirmation(false)}
                    title="Excluir Transação"
                    message={`Deseja realmente excluir a transação ${transaction?.name}`} />
            </div>
        </BaseModal >
    )
}
