import { MonthSummaryData } from "../models/types";
import PaywallButton from "./PaywallButton";
import { CategorySummary } from "./StackedSummary";
import TitleCard from "./TitleCard";

export default function StackedSummaryPaywall() {
    const groupedSummary: MonthSummaryData[] = [
        {
            id: "0",
            name: "Custos Fixos",
            budget_amount: 3500,
            total_amount: 3240,
        },
        {
            id: "1",
            name: "Custos Variáveis",
            budget_amount: 1500,
            total_amount: 800,
        },
        {
            id: "2",
            name: "Investimentos",
            budget_amount: 2000,
            total_amount: 2000,
        },
        {
            id: "3",
            name: "Metas",
            budget_amount: 1500,
            total_amount: 1500,
        },
        {
            id: "4",
            name: "Lazer",
            budget_amount: 1000,
            total_amount: 500,
        },
        {
            id: "2",
            name: "Estudos",
            budget_amount: 500,
            total_amount: 100,
        }
    ]
    return <TitleCard title={`Orçamento do Mês`} titleBoxClassName="md:flex-col md:items-start" subtitle={`Lembre-se de manter suas transações atualizadas para que os valores aqui exibidos reflitam os dados mais recentes`}>
        <div className="flex flex-col w-full">
            <div className="flex flex-col w-full opacity-20">
                {groupedSummary?.map((value, index) => <CategorySummary key={index} value={value} isLast={index === groupedSummary.length - 1} />)}
            </div>
            <PaywallButton className="mt-[-600px] md:mt-[-300px]" />
        </div>
    </TitleCard>
}