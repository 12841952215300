import { Progress } from "../models/types";

export default function CourseProgress({ progress, hideText, textColor = "text-green-700", progressColor = "bg-green-700" }: { progress: Progress; hideText?: boolean; progressColor?: string; textColor?: string; }) {
    const currentProgress = Number((progress.completed * 100) / progress.total).toFixed(2);
    const fixedProgress = Number(currentProgress) > 100 ? 100 : currentProgress;
    return <div aria-hidden="true" className="flex flex-grow w-full flex-row items-center gap-2">
        <div className="flex flex-grow rounded-full bg-gray-200">
            <div style={{ width: `${fixedProgress}%` }} className={`h-2 rounded-full ${progressColor}`} />
        </div>
        {!hideText && <div className={`flex min-w-fit text-sm font-medium ${textColor} text-right`}>{fixedProgress}%</div>}
    </div >
}