
const navigation: { name: string; href: string }[] = [
]

export function LandingPageHeader() {
    return <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div className="flex lg:flex-1">
                <a href="home" className="-m-1.5 p-1.5">
                    <span className="sr-only">Mais um na Bolsa</span>
                    <img
                        className="h-16 w-auto"
                        src="/images/white-logo.png"
                        alt=""
                    />
                </a>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
                {navigation.map((item) => (
                    <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-white">
                        {item.name}
                    </a>
                ))}
            </div>
            <div className="lg:flex lg:flex-1 lg:justify-end">
                <a href="login" className="text-sm font-semibold leading-6 text-white">
                    Login <span aria-hidden="true">&rarr;</span>
                </a>
            </div>
        </nav>
    </header>
}