import queryClient, { QueryKey } from "../hooks/state";
import { CreateCreditCard, CreditCard, CreditCardFetchParams, PaginatedResponse, UpdateCreditCard } from "../models/types";
import { httpDeleteAuthenticated, httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./Api";

const endpoints = {
  list: () => "/central/central/credit_card",
  retrieve: (id: string) => `/central/central/credit_card/${id}`,
};

export const getCreditCard = async (objectId: string) => {
  return httpGetAuthenticated<CreditCard>(endpoints.retrieve(objectId));
}

export const getCreditCards = async (params: CreditCardFetchParams) => {
  return httpGetAuthenticated<PaginatedResponse<CreditCard>>(endpoints.list(), {
    params,
  });
}

export const createCreditCard = async (payload: CreateCreditCard) => {
  const response = await httpPostAuthenticated<CreditCard>(endpoints.list(), payload);
  queryClient.invalidateQueries(QueryKey.creditCard);
  return response.data;
}

export const updateCreditCard = async (id: string, payload: UpdateCreditCard) => {
  const response = await httpPatchAuthenticated(endpoints.retrieve(id), payload);
  queryClient.invalidateQueries(QueryKey.creditCard);
  return response.data;
}

export const deleteCreditCard = async (id: string) => {
  const response = await httpDeleteAuthenticated(endpoints.retrieve(id));
  queryClient.invalidateQueries(QueryKey.creditCard);
  return response.data;
}