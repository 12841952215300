import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { useTransactionCategories, useTransactionCategory } from "../../hooks/useTransactionCategories";
import { TransactionCategory, TransactionCategoryFilterParams } from "../../models/types";
import { updateTransactionCategory } from "../../services/TransactionCategoriesService";
import { handleMoneyChange } from "../../utils/handleMoneyChange";
import { CategoryBadge } from "../Badge";
import { EditIcon } from "../Button";
import { CustomInput } from "../CustomInput";
import CustomSelectDropdown from "../monthly-budget/CustomSelectDropdown";
import BaseModal from "./BaseModal";

type BudgetSettingModalProps = {
    open: boolean;
    onClose: () => void;
    categoryId: string | null;
    setCreateCategoryOpen: (open: boolean) => void;
    onEditCategory: (categoryId: string) => void;
}

export default function BudgetSettingModal({ open, onClose, categoryId, setCreateCategoryOpen, onEditCategory }: BudgetSettingModalProps) {
    const [selectedCategory, setSelectedCategory] = useState<TransactionCategory | null>(null);
    const [amount, setAmount] = useState("");
    const [amountError, setAmountError] = useState("");
    const { data: category, isLoading } = useTransactionCategory(categoryId);
    const [loading, setLoading] = useState(false);
    const { setNotification } = useAppContext();
    useEffect(() => {
        if (category) {
            setSelectedCategory(category);
            if (category.budget_amount) {
                setAmount(category.budget_amount.toString().replace(".", ","));
            }
        } else {
            setSelectedCategory(null);
        }
    }, [category, open])

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (!selectedCategory) {
                return setAmountError("Selecione uma categoria");
            } else {
                setAmountError("");
            }
            const amountFloat = amount && amount !== "" ? parseFloat(amount.replace(",", ".")) : null;
            await updateTransactionCategory(selectedCategory.id, { budget_amount: amountFloat });
            onClose();
            setNotification({
                type: 'success',
                message: `Categoria ${categoryId ? "editada" : "adicionada"} com sucesso`,
                title: `Distribuição de Orçamento`
            })
        } catch (error) {
            setNotification({
                type: 'error',
                message: `Erro ao ${categoryId ? "editar" : "adicionar"} a configuração, tente novamente`,
                title: `Distribuição de Orçamento`
            })
        } finally {
            setLoading(false);
        }
    }

    return (
        <BaseModal open={open} onClose={onClose}>
            <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold">{categoryId ? "Editar orçamento" : "Adicionar ao orçamento"}</h2>
                </div>
                <h4 className="block text-sm font-medium text-gray-900">Categoria</h4>
                <h5 className="text-sm text-gray-500">Selecione uma categoria de gastos para incluir em seu orçamento mensal. Essa categoria ajudará a organizar e monitorar seus gastos de forma mais eficiente.</h5>
                <CustomSelectDropdown<TransactionCategory, TransactionCategoryFilterParams>
                    params={{
                        budget_amount_null: true,
                        page_size: 100,
                        category_type: "expense"
                    }}
                    disabled={!!categoryId}
                    createComponent={
                        <div className="w-full p-2">
                            <button className="rounded-md bg-indigo-500 py-2 w-full text-white font-medium" onClick={() => {
                                setCreateCategoryOpen(true)
                                onClose();
                            }}>Criar nova categoria</button>
                        </div>
                    }
                    secondComponent={(category) => <CategoryBadge category={category} />}
                    editComponent={(category) => {
                        return <EditIcon onClick={() => {
                            onEditCategory(category.id);
                            setCreateCategoryOpen(true)
                            onClose();
                        }} />
                    }}
                    useGetOptions={useTransactionCategories} nameKey="name" onChange={setSelectedCategory} selected={selectedCategory} />
                <CustomInput title="Valor"
                    description="Informe o objetivo de gastos mensais para essa categoria"
                    error={amountError}
                    onChange={(e) => {
                        handleMoneyChange(e, setAmount)
                        setAmountError("");
                    }}
                    value={amount}
                    placeholder="0.00"
                    fixedPlaceholder="R$"
                    name="amount"
                    id="amount"
                    type="text"
                />
                <button disabled={loading} onClick={() => {
                    handleSubmit();
                }} className="bg-indigo-600 hover:bg-indigo-700 rounded-md text-white text-sm font-medium py-1">{categoryId ? "Salvar Alterações" : "Adicionar Categoria"}</button>
            </div>
        </BaseModal>
    )
}
