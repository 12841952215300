import { useQuery } from "react-query";
import { TransactionCategoryFilterParams } from "../models/types";
import { getTransactionCategories, getTransactionCategory } from "../services/TransactionCategoriesService";
import { QueryKey } from "./state";

export const useTransactionCategory = (objectId: string | null) => {
    return useQuery([QueryKey.transactionCategory, objectId], async () => {
        if (!objectId || (objectId && objectId === "")) return null;
        const { data } = await getTransactionCategory(objectId);
        return data;
    });
}

export const useTransactionCategories = (params: TransactionCategoryFilterParams) => {
    return useQuery([QueryKey.transactionCategory, params], async () => {
        const { data } = await getTransactionCategories(params);
        return data;
    });
}

export const useTransactionCategoryTypes = () => {
    return useQuery([QueryKey.transactionCategoryType], async () => {
        const types: { name: string, id: "expense" | "income" }[] = [{
            name: "Receita",
            id: "income"
        }, {
            name: "Despesa",
            id: "expense"
        }]

        return {
            count: types.length,
            next: null,
            previous: null,
            results: types
        };
    });
}