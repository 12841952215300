import React from "react";
import { twMerge } from "tailwind-merge";
import SummarySkeletonLoader from "./skeleton/SummarySkeletonLoader";

type ExpenseSummaryProps = {
    values: {
        value: string;
        label: string;
        className: string;
        badge?: string | null;
        customValue?: React.ReactNode;
        extraComponent?: React.ReactNode;
    }[];
    loading?: boolean;
    className?: string;
}

export default function ExpenseSummary({ values, loading, className }: ExpenseSummaryProps) {
    return loading ? <SummarySkeletonLoader /> : <div className={twMerge("flex w-full flex-col md:flex-row flex-grow p-2 border border-gray-300 rounded-sm", className)} >
        {
            values.map((value, index) => {
                const isLastIndex = index === values.length - 1;
                return (
                    <div className={`flex flex-col px-0 md:px-2 py-2 md:py-0 w-full md:w-max flex-grow ${isLastIndex ? "" : "border-b md:border-b-0 md:border-r md:border-solid border-gray-300"}`}>
                        <h4 className="text-xs text-gray-500 text-center">{value.label}</h4>
                        {!value?.customValue && <h4 className={twMerge("text-center font-bold text-gray-500 text-md", value.className)}>{value.value}</h4>}
                        {value?.customValue}
                        {value?.extraComponent}
                        {value.badge && <span className="text-xs text-green-500 text-center">{value.badge}</span>}
                    </div>
                )
            })
        }
    </ div>
}