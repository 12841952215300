import { useTransactionCategories } from "../hooks/useTransactionCategories";
import { TransactionCategory, TransactionCategoryFilterParams } from "../models/types";

type CategoryDropdownProps = {
    selected: Pick<TransactionCategory, 'id' | 'name'> | null;
    params: TransactionCategoryFilterParams;
    onChange: (id: Pick<TransactionCategory, 'id' | 'name'> | null) => void;
    error?: boolean;
}

export default function CategoryDropdown({ selected, onChange, params, error }: CategoryDropdownProps) {
    const { data: categories } = useTransactionCategories(params);
    return <div className="flex flex-col"><select className={`text-xs py-2 px-4 rounded-2xl ${error ? "border-red-600" : ""}`} value={selected?.id ?? ""} onChange={(e) => onChange(categories?.results.find((c) => c.id === e.target.value) ?? null)}>
        <option key={""} value={""} disabled>Sem categoria</option>
        {categories?.results?.map((obj) => (
            <option key={obj.id} value={obj.id}>{obj.name}</option>
        ))}
    </select>
        {error && <h4 className="text-xs py-2 px-4 font-light text-red-600">Selecione uma categoria</h4>}
    </div>
}