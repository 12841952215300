import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import {
    AdjustmentsHorizontalIcon,
    ArchiveBoxIcon,
    ArrowLeftEndOnRectangleIcon,
    BanknotesIcon,
    Bars3Icon,
    CalendarDaysIcon,
    Cog6ToothIcon,
    CreditCardIcon,
    HomeIcon,
    LockClosedIcon,
    PlayIcon,
    PresentationChartBarIcon,
    QuestionMarkCircleIcon,
    TagIcon,
    XMarkIcon
} from '@heroicons/react/24/outline'
import { ForwardRefExoticComponent, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAppContext } from '../context/AppContext'
import { useAuthenticationContext } from '../context/AuthenticationContext'
import queryClient, { QueryKey } from '../hooks/state'
import { useUser } from '../hooks/useUsers'
import { getCompleteTutorial } from '../services/UserService'
import { setCookie } from '../utils/cookies'
import { getNameFirstLetters } from '../utils/helper'
import Notification from './Notification'
import PaymentModal from './modals/PaymentModal'
import WelcomeModal from './modals/WelcomeModal'

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function AuthenticatedLayout() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [openWelcomeModal, setOpenWelcomeModal] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const { setUser } = useAuthenticationContext();
    const { data: user } = useUser();

    const isSubscriber = user?.plan && user?.plan !== "FREE";
    const { setPaymentModal } = useAppContext();

    useEffect(() => {
        if (user) {
            if (!user.skip_tutorial && user.plan !== "FREE") {
                setOpenWelcomeModal(true);
            } else {
                setOpenWelcomeModal(false);
            }
        }
    }, [user]);


    const getIconSubscriberLock = (icon: ForwardRefExoticComponent<any>) => {
        if (isSubscriber) return icon;
        return LockClosedIcon
    }
    type NavigationItem = {
        available: boolean;
        subscriber: boolean;
        name: string;
        href: string;
        icon: ForwardRefExoticComponent<any>;
        current: boolean;
    }

    const homeNavigation: NavigationItem[] = [
        { subscriber: false, available: true, name: 'Inicio', href: 'inicio', icon: HomeIcon, current: location.pathname === '/inicio' },
    ]

    const settingsNavigation: NavigationItem[] = [
        { subscriber: true, available: true, name: 'Categorias', href: 'categorias', icon: getIconSubscriberLock(TagIcon), current: location.pathname === '/categorias' },
        // { subscriber: true, available: false, name: 'Familia', href: 'familia', icon: getIconSubscriberLock(UsersIcon), current: location.pathname === '/familia' },
    ]

    const toolsNavigation: NavigationItem[] = [
        { subscriber: true, available: true, name: 'Metas', href: 'metas', icon: getIconSubscriberLock(PresentationChartBarIcon), current: location.pathname === '/metas' },
        { subscriber: true, available: true, name: 'Orçamento', href: 'orcamento', icon: getIconSubscriberLock(CalendarDaysIcon), current: location.pathname === '/orcamento' },
        { subscriber: true, available: true, name: 'Despesas Fixas', href: 'despesas-recorrentes', icon: getIconSubscriberLock(AdjustmentsHorizontalIcon), current: location.pathname === '/despesas-recorrentes' },
        { subscriber: true, available: true, name: 'Fluxo de Caixa', href: 'transacoes', icon: getIconSubscriberLock(BanknotesIcon), current: location.pathname === '/transacoes' },
    ]
    const creditCardNavigation: NavigationItem[] = [
        { subscriber: true, available: true, name: 'Meus Cartões', href: 'cartoes', icon: getIconSubscriberLock(CreditCardIcon), current: location.pathname === '/cartoes' },
        { subscriber: true, available: true, name: 'Lançamentos', href: 'lancamentos-cartao', icon: getIconSubscriberLock(BanknotesIcon), current: location.pathname === '/lancamentos-cartao' },
    ]

    const courseNavigation: NavigationItem[] = [
        { subscriber: false, available: true, name: 'Cursos', href: 'cursos', icon: PlayIcon, current: location.pathname === '/cursos' },
        { subscriber: true, available: true, name: 'Materias Bônus', href: 'materiais', icon: getIconSubscriberLock(ArchiveBoxIcon), current: location.pathname === '/materiais' },
    ]

    const renderNavigation = ({ items, title }: { items: NavigationItem[], title: string }) => {
        return <div className="flex flex-col gap-1">
            <h3 className="text-xs font-semibold text-white">{title}</h3>
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                    <ul role="list" className="-mx-2 space-y-1">
                        {items.map((item) => (
                            <li key={item.name}>
                                <div
                                    onClick={() => {
                                        if (item.available === false) {
                                            return;
                                        }
                                        if (item.subscriber && !isSubscriber) {
                                            setPaymentModal(true);
                                            setSidebarOpen(false);
                                            return;
                                        }
                                        navigate(item.href)
                                        setSidebarOpen(false);
                                    }}
                                    className={classNames(
                                        item.current
                                            ? 'bg-gray-800 text-white'
                                            : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                        'group flex gap-x-3 rounded-md p-2 text-xs leading-6 items-center font-semibold cursor-pointer'
                                    )}
                                >
                                    <item.icon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                    {item.name}
                                </div>
                            </li>
                        ))}

                    </ul>
                </li>
            </ul>
        </div>
    }

    // const navigation = [
    //     { subscriber: false, name: 'Inicio', href: 'inicio', icon: HomeIcon, current: location.pathname === '/inicio' },
    //     { subscriber: false, name: 'Cursos', href: 'cursos', icon: PlayIcon, current: location.pathname === '/cursos' },
    //     { subscriber: true, name: 'Materias Bônus', href: 'materiais', icon: getIconSubscriberLock(ArchiveBoxIcon), current: location.pathname === '/materiais' },
    //     { subscriber: true, name: 'Metas', href: 'metas', icon: getIconSubscriberLock(PresentationChartBarIcon), current: location.pathname === '/metas' },
    //     { subscriber: true, name: 'Orçamento', href: 'orcamento', icon: getIconSubscriberLock(CalendarDaysIcon), current: location.pathname === '/orcamento' },
    //     { subscriber: true, name: 'Despesas Fixas', href: 'despesas-recorrentes', icon: getIconSubscriberLock(AdjustmentsHorizontalIcon), current: location.pathname === '/despesas-recorrentes' },
    //     { subscriber: true, name: 'Transações', href: 'transacoes', icon: getIconSubscriberLock(BanknotesIcon), current: location.pathname === '/transacoes' },
    //     // { name: 'Simulação', href: 'simulacao', icon: ChartBarIcon, current: location.pathname === '/simulacao' },

    // ]

    if (location.pathname === "/") {
        navigate("/inicio");
    }

    const handleLogout = () => {
        setUser(null);
        setCookie("token", "");
        setCookie("refresh", "");
        queryClient.invalidateQueries(QueryKey.users);
        navigate("/home");
    }
    return (
        <div className="max-w-[100vw] overflow-hidden">
            <div>
                <Transition show={sidebarOpen}>
                    <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                        <TransitionChild
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </TransitionChild>

                        <div className="fixed inset-0 flex">
                            <TransitionChild
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                                    <TransitionChild
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                            <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </TransitionChild>
                                    {/* Sidebar component, swap this element with another sidebar if you like */}
                                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                                        <div className="flex h-16 shrink-0 items-center">
                                            <img
                                                className="h-12 w-auto"
                                                src="/images/white-logo.png"
                                                alt="Mais um na Bolsa"
                                            />
                                        </div>
                                        <nav className="flex flex-1 flex-col gap-y-3">
                                            {renderNavigation({ items: homeNavigation, title: "Dashboards" })}
                                            {renderNavigation({ items: courseNavigation, title: "Ensino" })}
                                            {renderNavigation({ items: toolsNavigation, title: "Ferramentas" })}
                                            {renderNavigation({ items: creditCardNavigation, title: "Cartões de Crédito" })}
                                            {renderNavigation({ items: settingsNavigation, title: "Configurações" })}
                                            <ul className="-mx-6 mt-auto">
                                                {user?.is_admin && <li>
                                                    <div
                                                        onClick={() => {
                                                            navigate("admin")
                                                            setSidebarOpen(false);
                                                        }}
                                                        className="flex items-center gap-x-4 px-6 py-3 text-xs font-semibold leading-6 text-white hover:bg-gray-800"
                                                    >
                                                        <Cog6ToothIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                                        <span aria-hidden="true">Admin Panel</span>
                                                    </div>
                                                </li>}
                                                <li>
                                                    <div
                                                        onClick={() => {
                                                            navigate("/suporte")
                                                            setSidebarOpen(false);
                                                        }}
                                                        className="flex items-center gap-x-4 px-6 py-3 text-xs font-semibold leading-6 text-white hover:bg-gray-800"
                                                    >
                                                        <QuestionMarkCircleIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                                        <span className="sr-only">Suporte</span>
                                                        <span aria-hidden="true">Suporte</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <a
                                                        onClick={() => handleLogout()}
                                                        className="flex cursor-pointer items-center gap-x-4 px-6 py-3 text-xs font-semibold leading-6 text-white hover:bg-gray-800"
                                                    >
                                                        <ArrowLeftEndOnRectangleIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                                        <span aria-hidden="true">Sair</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </Dialog>
                </Transition>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-48 lg:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
                        <div className="flex h-16 shrink-0 items-center">
                            <img
                                className="h-12 w-auto"
                                src="/images/white-logo.png"
                                alt="Your Company"
                            />
                        </div>
                        <nav className="flex flex-1 flex-col justify-start">
                            <div className="flex flex-col gap-y-3">
                                {renderNavigation({ items: homeNavigation, title: "Dashboards" })}
                                {renderNavigation({ items: courseNavigation, title: "Ensino" })}
                                {renderNavigation({ items: toolsNavigation, title: "Ferramentas" })}
                                {renderNavigation({ items: creditCardNavigation, title: "Cartões de Crédito" })}
                                {renderNavigation({ items: settingsNavigation, title: "Configurações" })}
                            </div>
                            <ul className="-mx-6 mt-auto">
                                {user?.is_admin && <li>
                                    <div
                                        onClick={() => navigate("admin")}
                                        className="flex items-center gap-x-4 px-6 py-3 text-xs font-semibold leading-6 text-white hover:bg-gray-800"
                                    >
                                        <Cog6ToothIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                        <span aria-hidden="true">Admin Panel</span>
                                    </div>
                                </li>}
                                <li>
                                    <div
                                        onClick={() => navigate("/suporte")}
                                        className="flex items-center gap-x-4 px-6 py-3 text-xs font-semibold leading-6 text-white hover:bg-gray-800"
                                    >
                                        <QuestionMarkCircleIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                        <span className="sr-only">Suporte</span>
                                        <span aria-hidden="true">Suporte</span>
                                    </div>
                                </li>
                                <li>
                                    <div
                                        onClick={() => navigate("/perfil")}
                                        className="flex items-center gap-x-4 px-6 py-3 text-xs font-semibold leading-6 text-white hover:bg-gray-800"
                                    >
                                        <span className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-indigo-500">
                                            <span className="text-[10px] font-medium leading-none text-white">{getNameFirstLetters(user?.name ?? "")}</span>
                                        </span>
                                        <span className="sr-only">Your profile</span>
                                        <span aria-hidden="true">{user?.first_name}</span>
                                    </div>
                                </li>
                                <li>
                                    <a
                                        onClick={() => handleLogout()}
                                        className="flex cursor-pointer items-center gap-x-4 px-6 py-3 text-xs font-semibold leading-6 text-white hover:bg-gray-800"
                                    >
                                        <ArrowLeftEndOnRectangleIcon className="h-5 w-5 shrink-0" aria-hidden="true" />
                                        <span aria-hidden="true">Sair</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
                    <button type="button" className="-m-2.5 p-2.5 text-gray-400 lg:hidden" onClick={() => setSidebarOpen(true)}>
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <div className="flex-1 text-xs font-semibold leading-6 text-white">Menu</div>
                    <div onClick={() => navigate("/perfil")} className="cursor-pointer">
                        <span className="sr-only">Your profile</span>
                        <span className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-indigo-500">
                            <span className="text-xs font-medium leading-none text-white">{getNameFirstLetters(user?.name ?? "")}</span>
                        </span>
                    </div>
                </div>

                <main className="py-8 lg:pl-48 overflow-hidden">
                    <div className="px-4 sm:px-6 lg:px-8"><Outlet /></div>
                </main>
            </div>
            <WelcomeModal open={openWelcomeModal} onClose={async () => {
                await getCompleteTutorial();
                setOpenWelcomeModal(false)
            }} />
            <Notification />
            <PaymentModal />
        </div>
    )
}
