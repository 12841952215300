import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { TransactionCategory } from "../models/types";
import { BudgetChart } from "./charts/BudgetChart";
import PaywallButton from "./PaywallButton";

export default function BudgetSummaryPaywall() {
    const categories: {
        results: TransactionCategory[];
    } = {
        results: [
            {
                "id": "019f7c92-a0ca-4a0a-90b2-0da953da7cc6",
                "name": "Custos Fixos",
                "category_type": "expense",
                "budget_amount": 3500.00
            },
            {
                "id": "af912a2b-66b0-4376-9384-9f54946a47d6",
                "name": "Custos Variáveis",
                "category_type": "expense",
                "budget_amount": 1500.00
            },
            {
                "id": "6905ea8e-e427-484f-ab1e-89dd6361725a",
                "name": "Investimentos",
                "category_type": "investment",
                "budget_amount": 2000.00
            },
            {
                "id": "25b76ef9-f236-45bd-ab88-95a5dbd2f7ec",
                "name": "Metas",
                "category_type": "investment",
                "budget_amount": 1500.00
            },
            {
                "id": "bc5448ab-c257-40c2-9365-367c23b19ee9",
                "name": "Lazer",
                "category_type": "expense",
                "budget_amount": 1000.00
            },
            {
                "id": "bc5448ab-c257-40c2-9365-367c23b19ee9",
                "name": "Estudos",
                "category_type": "expense",
                "budget_amount": 500.00
            }
        ]
    }
    const navigate = useNavigate();
    return <div className="flex flex-col border border-gray-300 rounded-xl w-full sm:w-1/2 flex-grow items-center">
        <div className="flex items-center px-4 py-2 gap-3 bg-gray-100 w-full border-b border-gray-300 rounded-t-xl">
            <CalendarDaysIcon className="h-6 w-6 text-indigo-700" />
            <h3 className="text-sm font-semibold leading-7">
                Distribuição do Orçamento
            </h3>
        </div>
        <div className="flex h-full max-w-max overflow-hidden bg-white w-full px-4 py-1 rounded-b-xl items-center justify-center opacity-20">
            {categories?.results?.length === 0 && <div className="flex py-5 flex-col items-center justify-center gap-1">
                <h3 className="font-semibold text-sm">Não configurado</h3>
                <h3 className="text-sm text-gray-600">Configure seu orçamento mensal</h3>
                <h4 onClick={() => navigate("/orcamento")} className="mt-2 text-sm cursor-pointer hover:bg-indigo-800 text-white py-1 px-4 rounded-md bg-indigo-700">Configurar</h4>
            </div>}
            {categories?.results && categories?.results?.length > 0 && <BudgetChart categories={categories.results} />}
        </div>
        <PaywallButton className="mt-[-300px] md:mt-[-300px]" />
    </div>
}