import { QueryClientProvider } from "react-query";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AulaComponent } from "./components/Aula";
import AuthProtect from "./components/AuthProtect";
import { LandingPage } from "./components/LandingPage";
import { Login } from "./components/Login";
import NotFound from "./components/NotFound";
import PrivacyPolicy from "./components/PrivacyPolicy";
import AuthenticatedLayout from "./components/Sidebar";
import { AppProvider } from "./context/AppContext";
import { AuthenticationProvider } from "./context/AuthenticationContext";
import queryClient from "./hooks/state";
import AdminPage from "./pages/Admin";
import AdminCoursePage from "./pages/AdminCourse";
import AdminCourseModulePage from "./pages/AdminCourseModule";
import AISummaryPage from "./pages/AISummary";
import CategoriesPage from "./pages/Categories";
import CourseDetail from "./pages/CourseDetail";
import CourseFinalized from "./pages/CourseFinalized";
import CoursesPage from "./pages/Courses";
import CreditCardsPage from "./pages/CreditCards";
import CreditCardTransactionsPage from "./pages/CreditCardTransactions";
import FilesPage from "./pages/Files";
import GoalsPage from "./pages/Goals";
import HelpPage from "./pages/Help";
import HomePage from "./pages/Home";
import ImportTransactionsPage from "./pages/ImportTransactions";
import LeadPage from "./pages/LeadPage";
import PaymentApproved from "./pages/PaymentApproved";
import Profile from "./pages/Profile";
import RecurrenceTransactionPage from "./pages/RecurrenceTransaction";
import BudgetSettingsPage from "./pages/Settings";
import SignupPage from "./pages/SignupPage";
import SimulationPage from "./pages/Simulation";
import TransactionsPage from "./pages/Transactions";

function App() {
  return <div>
    <QueryClientProvider client={queryClient}>
      <AuthenticationProvider>
        <AppProvider>
          <Router>
            <Routes>
              <Route path="/" element={<AuthProtect><AuthenticatedLayout /></AuthProtect>}>
                <Route path="/inicio" element={<HomePage />} />
                <Route path="/curso/:courseId" element={<CourseDetail />} />
                <Route path="/curso/:courseId/finalizado" element={<CourseFinalized />} />
                <Route path="/curso/:courseId/modulo/:moduleId/aula/:classId" element={<AulaComponent />} />
                <Route path="/metas" element={<GoalsPage />} />
                <Route path="/despesas-recorrentes" element={<RecurrenceTransactionPage />} />
                <Route path="/transacoes" element={<TransactionsPage />} />
                <Route path="/simulacao" element={<SimulationPage />} />
                <Route path="/orcamento" element={<BudgetSettingsPage />} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/admin/course/:id" element={<AdminCoursePage />} />
                <Route path="/admin/course/:courseId/module/:moduleId" element={<AdminCourseModulePage />} />
                <Route path="/perfil" element={<Profile />} />
                <Route path="/suporte" element={<HelpPage />} />
                <Route path="/materiais" element={<FilesPage />} />
                <Route path="/cursos" element={<CoursesPage />} />
                <Route path="/sugestoes-alberto" element={<AISummaryPage />} />
                <Route path="/categorias" element={<CategoriesPage />} />
                <Route path="/cartoes" element={<CreditCardsPage />} />
                <Route path="/lancamentos-cartao" element={<CreditCardTransactionsPage />} />
                <Route path="/importar-transacoes" element={<ImportTransactionsPage />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route path="/home" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              {/* <Route path="/inscreva-se" element={<LPRegister />} /> */}
              <Route path="/pagamento-aprovado" element={<PaymentApproved />} />
              <Route path="/material-gratuito/do-caos-ao-controle-financeiro" element={<LeadPage />} />
              <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
              <Route path="/signup" element={<SignupPage />} />
              {/* <Route path="/signup" element={<Signup />} /> */}
            </Routes>
          </Router>
        </AppProvider>
      </AuthenticationProvider>
    </QueryClientProvider>
  </div>
}

export default App;