import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';

type CustomSelectDropdownProps = {
    value: "Todos" | "Receita" | "Despesa";
    onChange: (value: "Todos" | "Receita" | "Despesa") => void;
    label?: string;
}

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}

export default function TransactionTypeFilterDropdown({
    onChange,
    value,
    label,
}: CustomSelectDropdownProps) {
    const options = ["Todos", "Receita", "Despesa"]
    const handleClear = () => {
        onChange("Todos")
    };
    return (
        <Listbox value={value} onChange={onChange}>
            {({ open }) => (
                <div className={`flex flex-col gap-1 flex-wrap flex-grow w-full md:max-w-36`}>
                    {label && <Label className="block text-sm font-medium leading-6 text-gray-900">{label}</Label>}
                    <div className="relative">
                        <ListboxButton className="flex flex-row  justify-between items-center w-full cursor-default rounded-md bg-white py-1.5 pl-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            {value}
                            <div className="flex flex-row gap-1 items-center">
                                {value && <span className="inset-y-0 right-0 flex items-center">
                                    <XMarkIcon onClick={(e) => { e.stopPropagation(); handleClear(); }} className="cursor-pointer h-5 w-5 text-gray-400 hover:bg-gray-100 hover:rounded-md" aria-hidden="true" />
                                </span>}
                                <span className="pointer-events-none inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </div>
                        </ListboxButton>

                        <ListboxOptions
                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                        >
                            {options.map((obj) => (
                                <ListboxOption
                                    key={obj}
                                    className={({ focus }) =>
                                        classNames(
                                            focus ? 'bg-indigo-50' : '',
                                            !focus ? 'text-gray-900' : '',
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                        )
                                    }
                                    value={obj}
                                >
                                    {({ selected, focus }) => (
                                        <>
                                            <div className="flex items-center gap-2">
                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                                                    {obj}
                                                </span>
                                            </div>

                                            {selected ? (
                                                <span
                                                    className={classNames(
                                                        focus ? 'text-white' : 'text-indigo-600',
                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    )}
                                                >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </ListboxOption>
                            ))}
                        </ListboxOptions>
                    </div>
                </div>
            )}
        </Listbox>
    )
}
