import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { useCreditCardTransaction } from "../../hooks/useCreditCards";
import { CreditCard, TransactionCategory } from "../../models/types";
import { createCreditCardTransaction, deleteCreditCardTransaction, updateCreditCardTransaction } from "../../services/CreditCardTransactions";
import { handleMoneyChange } from "../../utils/handleMoneyChange";
import { Button } from "../Button";
import CategoryDropdown from "../CategoryDropdown";
import CreditCardDropdown from "../CreditCardDropdown";
import DateBadge from "../DateBadge";
import InstallmentsSwitch from "../InstallmentsSwitch";
import ConfirmationModal from "./ConfirmationModal";
import SidebarModal from "./SidebarModal";

type BaseModalProps = {
    open: boolean;
    onClose: () => void;
    transactionId: string | null;
}

export default function CreditCardTransactionModal({ open, onClose, transactionId }: BaseModalProps) {
    const [title, setTitle] = useState<string>("");
    const [amount, setAmount] = useState<string>("0,00");
    const [category, setCategory] = useState<Pick<TransactionCategory, 'id' | 'name'> | null>(null);
    const [card, setCard] = useState<Pick<CreditCard, 'id' | 'name' | 'color'> | null>(null);
    const [cardError, setCardError] = useState<boolean>(false);
    const [titleError, setTitleError] = useState<boolean>(false);
    const [amountError, setAmountError] = useState<boolean>(false);
    const [transactionDate, setTransactionDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
    const [installments, setInstallments] = useState<number>(1);
    const [installmentsError, setInstallmentsError] = useState<boolean>(false);
    const [categoryError, setCategoryError] = useState<boolean>(false);
    const [hasInstallments, setHasInstallments] = useState<boolean>(false);
    const { setNotification } = useAppContext();
    const [loading, setLoading] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);

    const { data: transaction } = useCreditCardTransaction(transactionId);

    const handleDelete = async () => {
        try {
            setLoading(true);
            await deleteCreditCardTransaction(transaction!.id);
            onClose();
            setNotification({
                message: "Despesa removida com sucesso",
                type: "success",
                title: "Sucesso"
            })
        } catch (e) {
            setNotification({
                message: "Erro ao remover despesa do cartão",
                type: "error",
                title: "Erro"
            })
        } finally {
            setOpenDelete(false);
            setLoading(false);
        }
    }

    const handleSubmit = async () => {
        try {
            setLoading(true);
            let hasError = false;
            if (!card) {
                setCardError(true);
                hasError = true;
            } else {
                setCardError(false);
            }
            if (!title || title === "") {
                setTitleError(true);
                hasError = true;
            } else {
                setTitleError(false);
            }
            if (!amount || amount === "0,00" || amount === "") {
                setAmountError(true);
                hasError = true;
            } else {
                setAmountError(false);
            }
            if (!category) {
                setCategoryError(true);
                hasError = true;
            } else {
                setCategoryError(false);
            }
            if (hasInstallments && installments <= 0) {
                setInstallmentsError(true);
                hasError = true;
            } else {
                setInstallmentsError(false);
            }
            if (hasError) {
                return;
            }
            if (transaction) {
                await updateCreditCardTransaction(transaction.id, {
                    amount: parseFloat(amount.replace(",", ".")),
                    category: category!.id,
                    name: title,
                    transaction_date: transactionDate

                })
            } else {
                await createCreditCardTransaction({
                    amount: parseFloat(amount.replace(",", ".")),
                    category: category!.id,
                    credit_card: card!.id,
                    installment_amount: hasInstallments ? installments : 1,
                    installment_number: 1,
                    name: title,
                    total_amount: parseFloat(amount.replace(",", ".")),
                    transaction_date: transactionDate
                })
            }
            onClose();
        } catch (e) {
            setNotification({
                message: "Erro ao adicionar despesa no cartão",
                type: "error",
                title: "Erro"
            })
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (transaction) {
            setTitle(transaction.name);
            setAmount(transaction.amount.toString());
            setCategory(transaction.category_name);
            setCard(transaction.credit_card_name);
            setTransactionDate(transaction.transaction_date);
            setInstallments(transaction.installment_amount);
            setHasInstallments(transaction.installment_amount > 1);
        } else {
            setTitle("");
            setAmount("0,00");
            setCategory(null);
            setCard(null);
            setTransactionDate(format(new Date(), "yyyy-MM-dd"));
            setInstallments(1);
            setHasInstallments(false);
        }
    }, [transaction, open]);

    return (
        <SidebarModal onDelete={transaction ? () => setOpenDelete(true) : undefined} title="Adicionar despesa no cartão" open={open} onClose={onClose} action={
            <Button
                disabled={loading}
                loading={loading}
                onClick={() => handleSubmit()}
                className="bg-blue-600 hover:bg-blue-700 w-full rounded-2xl text-white"
                title={transaction ? "Salvar" : "Adicionar"}
            />
        }>
            <div className="flex flex-col gap-2 flex-grow mt-2">
                {transaction && <ConfirmationModal
                    open={openDelete}
                    onClose={() => setOpenDelete(false)}
                    onCancel={() => setOpenDelete(false)}
                    onConfirm={() => handleDelete()}
                    title="Remover despesa"
                    message="Deseja realmente remover essa despesa?" />}
                <h4 className="text-xs font-semibold text-gray-700">Valor</h4>
                <div className={`flex flex-row gap-1 items-center ${amountError ? "border-b border-red-600" : ""}`}>
                    <h4 className="text-sm font-semibold">R$</h4>
                    <input
                        type="text"
                        name="amount"
                        id="amount"
                        placeholder="0,00"
                        value={amount}
                        onFocus={(e) => {
                            if (e.target.value === "0,00") {
                                setAmount("");
                            }
                        }}
                        onChange={(e) => {
                            setAmountError(false);
                            handleMoneyChange(e, setAmount)
                        }}
                        className={`p-0 text-lg border-none font-semibold focus:ring-0 focus:outline-none focus:border-b-2 focus:underline cursor-pointer focus:border-blue-500`}
                    />
                </div>
                {amountError && <h4 className="px-2 text-xs font-light text-red-600">O valor é obrigatório</h4>}
                <h4 className="text-xs font-semibold text-gray-700">Pagamento</h4>
                <InstallmentsSwitch disabled={!!transaction} hasInstallments={hasInstallments} setHasInstallments={(value: boolean) => {
                    setHasInstallments(value);
                    if (value) setInstallments(1);
                }} />
                {hasInstallments && <>
                    <h4 className="text-xs font-semibold text-gray-700">Parcelas</h4>
                    {transaction && <h4 className="text-sm">{transaction.installment_number}/{transaction.installment_amount}</h4>}
                    {!transaction &&
                        <input
                            type="number"
                            name="installments"
                            id="installments"
                            placeholder="Número de parcelas"
                            value={installments}
                            onChange={(e) => {
                                setInstallmentsError(false);
                                setInstallments(parseInt(e.target.value));
                            }}
                            className={`py-2 px-4 rounded-2xl text-xs cursor-pointer ${installmentsError ? "border-red-600" : ""}`} />}
                    {installmentsError && <h4 className="px-4 text-xs font-light text-red-600">O número de parcelas deve ser maior que 0</h4>}
                </>}
                <h4 className="text-xs font-semibold text-gray-700">Título</h4>
                <input
                    type="text"
                    name="title"
                    id="title"
                    placeholder="Título da transação"
                    value={title}
                    onChange={(e) => {
                        setTitleError(false);
                        setTitle(e.target.value)
                    }}
                    className={`py-2 px-4 rounded-2xl text-xs cursor-pointer ${titleError ? "border-red-600" : ""}`}
                />
                {titleError && <h4 className="px-4 text-xs font-light text-red-600">O título é obrigatório</h4>}
                <h4 className="text-xs font-semibold text-gray-700">Data</h4>
                <DateBadge date={transactionDate} setDate={setTransactionDate} />
                <h4 className="text-xs font-semibold text-gray-700">Categoria</h4>
                <CategoryDropdown error={categoryError} params={{ page_size: 100, category_type: "expense" }} selected={category} onChange={(category: Pick<TransactionCategory, 'id' | 'name'> | null) => {
                    setCategory(category)
                }} />
                <h4 className="text-xs font-semibold text-gray-700">Cartão</h4>
                <CreditCardDropdown disabled={!!transaction} error={cardError} params={{ page_size: 25 }} selected={card} onChange={(card) => {
                    setCardError(false);
                    setCard(card);
                }} />
            </div>
        </SidebarModal >
    )
}
