import { TrashIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../components/Button";
import Card from "../components/Card";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import BaseTable from "../components/monthly-budget/Table";
import ThreeDotsMenu from "../components/ThreeDotsMenu";
import { useAppContext } from "../context/AppContext";
import { ImportTransactionType } from "../models/types";
import { createManyMonthlyTransactions } from "../services/MonthlyTransactionService";
import { formatBrazilianCurrence } from "../utils/helper";
import { importTransactionsColumns } from "./shared/ImportTransactionsTable";

export default function ImportTransactionsPage() {
    const location = useLocation();
    const { setNotification } = useAppContext();
    const bigJson: ImportTransactionType[] = location.state?.transactions;
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<ImportTransactionType[]>(bigJson.map((t) => ({ ...t, amount: formatBrazilianCurrence(t.amount) })));
    const onChange = (row: ImportTransactionType, fieldKey: string, value: any) => {
        const newData = data.map((d) => {
            if (d.external_id === row.external_id) {
                return { ...d, [fieldKey]: value }
            }
            return d;
        })
        setData(newData);
    }
    const onRemove = (row: ImportTransactionType) => {
        const newData = data.filter((d) => d.external_id !== row.external_id);
        setData(newData);
    }
    const threeDotsItems = [
        {
            name: 'Remover duplicidades', description: 'Remova todas transações duplicadas', onClick: () => {
                setOpenRemoveDuplicated(true);
            }, icon: TrashIcon
        },
    ]

    const navigate = useNavigate();

    const handleCreate = async () => {
        try {
            setLoading(true);
            const mappedData = await Promise.all(
                data.map(async (d) => ({
                    amount: Number(`${d.amount}`.replace(/[.]/g, "").replace(/[,]/g, ".")),
                    category: d?.category?.id ?? null,
                    month: d.payment_date,
                    payment_date: d.payment_date,
                    name: d.name,
                    paid: true,
                    type: d.type as "credit" | "debit",
                    external_id: d.external_id,
                }))
            );
            await createManyMonthlyTransactions(mappedData);
            setNotification({
                message: "Transações importadas com sucesso",
                type: "success",
                title: "Sucesso"
            })
            navigate("/transacoes");
        } catch (e) {
            setNotification({
                message: "Erro ao importar transações",
                type: "error",
                title: "Erro"
            })
        } finally {
            setLoading(false);
        }
    }

    const [openRemoveDuplicated, setOpenRemoveDuplicated] = useState<boolean>(false);
    return <Card >
        <div className="flex w-full flex-col gap-1">
            <h3 className="text-md md:text-lg font-semibold text-left">Importar Transações</h3>
            <div className="flex flex-row justify-between items-center">
                <h3 className="text-sm md:text-xs text-gray-500 mt-1 mb-3 text-left">Revise as transações identificadas no arquivo importado, você pode alterar o valor, nome e categoria. Caso a transação seja identificada como duplicada haverá uma sinalização.<br />As transações somente serão importadas após clicar no botão <b>Importar Transações</b></h3>
                <div className="">
                    <ThreeDotsMenu items={threeDotsItems} />
                </div>
            </div>
            <BaseTable
                hidePagination
                useGetData={() => ({ data: { results: data, next: null, previous: null } })}
                columns={importTransactionsColumns(onChange, onRemove)} params={{}} />
            <Button loading={loading} className={`${data.length === 0 ? "bg-gray-300 hover:bg-gray-300" : ""} mt-3 py-2`} disabled={data.length === 0 || loading} onClick={() => handleCreate()} title="Importar Transações" />
        </div>
        <ConfirmationModal open={openRemoveDuplicated} onClose={() => setOpenRemoveDuplicated(false)} onCancel={() => setOpenRemoveDuplicated(false)}
            onConfirm={() => {
                setData(data.filter((d) => !d.exists));
                setOpenRemoveDuplicated(false);
            }} title="Remover duplicidades" message={`Tem certeza que deseja remover todas as transações marcadas como duplicadas?`} />
    </Card>
}