import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CourseCard from "../components/CourseCard";
import LoadingSpinner from "../components/Loading";
import PaginatedCard from "../components/Pagination";
import TitleCard from "../components/TitleCard";
import { useCourse, useCourses } from "../hooks/useCourses";
import { Course, CourseFilterParams } from "../models/types";

export default function CourseFinalized() {
    const navigate = useNavigate()
    const { courseId } = useParams();
    if (!courseId) {
        navigate("/admin")
    }
    const { data: course } = useCourse(courseId ?? "");
    const [openCourse, setOpenCourse] = useState<boolean>(false);
    // const { data: modules } = useCourseModule
    return !course || !courseId ? <LoadingSpinner /> : <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6 flex w-full flex-col items-center">
            <div className="flex w-full md:w-fit px-4 py-2 rounded-2xl flex-row flex-nowrap justify-center items-center gap-4 border-2 border-dashed bg-yellow-100 border-yellow-500">
                <img src="/images/achievement.png" alt="Curso finalizado" className="w-28 h-28 md:w-36 md:h-36 mt-2" />
                <div className="flex flex-col gap-1">
                    <h3 className="font text-md font-bold text-gray-900">Muito bem!</h3>
                    <h3 className="font text-sm font-light max-w-xs text-gray-900">Você finalizou a trilha de ensino {course.title}</h3>
                </div>
            </div>
            <TitleCard title={`Conteúdo relacionado que você pode gostar`} titleBoxClassName="md:flex-col md:items-start mt-6">
                <PaginatedCard<Course, CourseFilterParams>
                    useGetData={useCourses}
                    pageSize={5}
                    params={{ highlight: true }}
                    render={(course) => <CourseCard key={course.id} course={course} />} />
            </TitleCard>
        </div>
    </div>
}