import { Switch } from "@headlessui/react";

type InstallmentsSwitchProps = {
    hasInstallments: boolean;
    setHasInstallments: (visible: boolean) => void;
    disabled?: boolean;
}

export default function InstallmentsSwitch({ hasInstallments, setHasInstallments, disabled }: InstallmentsSwitchProps) {
    return <Switch
        disabled={disabled}
        checked={hasInstallments}
        onChange={(v: boolean) => !disabled && setHasInstallments(v)}
        className="group relative inline-flex w-full h-7 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
    >
        <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-2xl bg-gray-200">
            <div className="flex w-full h-full justify-around">
                <h4 className="text-xs text-gray-900 mt-[6px]">{"À vista"}</h4>
                <h4 className="text-xs text-gray-900 mt-[6px]">{"Parcelado"}</h4>
            </div>
        </span>
        <span
            aria-hidden="true"
            className="pointer-events-none absolute left-0 inline-block h-7 w-1/2 transform rounded-full border border-gray-200 bg-blue-600 shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-full"
        >
            <h4 className="text-xs text-white mt-1">{hasInstallments ? "Parcelado" : "À vista"}</h4>
        </span>
    </Switch>
}