import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { ArrowLeftIcon, TrashIcon } from '@heroicons/react/24/outline';

type BaseModalProps = {
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
    action?: React.ReactNode;
    title?: string;
    onDelete?: () => void;
};

export default function SidebarModal({ children, open, onClose, action, title, onDelete }: BaseModalProps) {
    return (
        <Transition show={open}>
            <Dialog className="relative z-40" onClose={() => onClose()}>
                {/* Background overlay */}
                <TransitionChild
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-40 overflow-hidden">
                    <div className="absolute inset-y-0 right-0 flex max-w-full">
                        <TransitionChild
                            enter="transform transition ease-in-out duration-300 sm:duration-500"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-300 sm:duration-500"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <DialogPanel
                                className="w-screen max-w-xs sm:max-w-sm transform overflow-hidden bg-white shadow-xl"
                            >
                                <div className="p-6 flex flex-col justify-between min-h-screen">
                                    <div className="flex flex-col justify-start gap-1">
                                        {title && <div className="flex flex-row justify-between w-full items-center">
                                            <div className="flex flex-row gap-2 items-center justify-start">
                                                <ArrowLeftIcon onClick={() => onClose()} className="h-4 w-4 text-gray-700 cursor-pointer" />
                                                <h4 className="text-md font-semibold text-gray-900">{title}</h4>
                                            </div>
                                            {onDelete && <TrashIcon onClick={() => onDelete()} className="w-4 cursor-pointer min-w-4 h-4 min-h-4 text-red-600" />}
                                        </div>}
                                        {children}
                                    </div>
                                    {action && <div className="flex w-full pt-2 border-t justify-center border-gray-200">
                                        {action}
                                    </div>}
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
