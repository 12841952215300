import { PresentationChartBarIcon } from "@heroicons/react/24/outline";
import { formatToBrazilianCurrency } from "../utils/handleMoneyChange";
import CourseProgress from "./CourseProgress";
import PaywallButton from "./PaywallButton";

export default function GoalSummaryPaywall() {
    const goals = {
        results: [
            {
                "id": "0",
                "created_at": "2024-09-21T21:30:28.234645Z",
                "updated_at": "2024-09-21T21:30:28.234970Z",
                "name": "Entrada para a Casa",
                "amount": 54500.00,
                "goal_amount": 100000.00,
                "goal_date": "2054-01-01",
                "progress": {
                    "is_completed": false,
                    "pending": 45500.0
                }
            },
            {
                "id": "1",
                "created_at": "2024-09-21T21:30:28.234645Z",
                "updated_at": "2024-09-21T21:30:28.234970Z",
                "name": "Casamento",
                "amount": 15390.00,
                "goal_amount": 60000.00,
                "goal_date": "2054-01-01",
                "progress": {
                    "is_completed": false,
                    "pending": 44610.0
                }
            },
            {
                "id": "0",
                "created_at": "2024-09-21T21:30:28.234645Z",
                "updated_at": "2024-09-21T21:30:28.234970Z",
                "name": "Trocar de carro",
                "amount": 18750.00,
                "goal_amount": 80000.00,
                "goal_date": "2054-01-01",
                "progress": {
                    "is_completed": false,
                    "pending": 61250.0
                }
            },
            {
                "id": "0",
                "created_at": "2024-09-21T21:30:28.234645Z",
                "updated_at": "2024-09-21T21:30:28.234970Z",
                "name": "Viagem para Disney",
                "amount": 20000.00,
                "goal_amount": 20000.00,
                "goal_date": "2054-01-01",
                "progress": {
                    "is_completed": true,
                    "pending": 0
                }
            },
        ]
    }
    return <div className="flex flex-col border border-gray-300 rounded-xl w-full sm:w-1/2 flex-grow items-center">
        <div className="flex items-center px-4 py-2 gap-3 bg-gray-100 w-full border-b border-gray-300 rounded-t-xl">
            <PresentationChartBarIcon className="h-6 w-6 text-indigo-700" />
            <h3 className="text-sm font-semibold leading-7">
                Metas
            </h3>
        </div>
        <div className="bg-white w-full px-4 py-1 rounded-b-xl opacity-20">
            {goals?.results?.length === 0 && <div className="flex py-5 flex-col items-center justify-center gap-1">
                <h3 className="font-semibold text-sm">Nenhuma meta</h3>
                <h3 className="text-sm text-gray-600">Adicione suas metas para acompanhamento</h3>
                <h4 onClick={() => { }} className="mt-2 text-sm cursor-pointer hover:bg-indigo-800 text-white py-1 px-4 rounded-md bg-indigo-700">Adicionar Metas</h4>
            </div>}
            {goals?.results?.map((goal, idx) => <div key={goal.id} className={`flex py-1 flex-col ${idx + 1 !== goals?.results?.length ? "border-b border-gray-300" : ""}`}>
                <div className="flex flex-row gap-2 justify-between items-center">
                    <h4 className="text-sm leading-7 font-light truncate">{goal.name}</h4>
                    <span className="text-sm leading-7 font-light">R${formatToBrazilianCurrency(`${goal.goal_amount}`)}</span>
                </div>
                <CourseProgress progress={{
                    completed: goal.amount,
                    total: goal.goal_amount,
                    is_completed: goal.progress.is_completed,
                }} />
            </div>)}
            <div className="flex justify-center p-2">
                <h4 onClick={() => { }} className="text-sm cursor-pointer text-indigo-700">Ver todas 10 metas</h4>
            </div>
        </div>
        <PaywallButton className="mt-[-300px] md:mt-[-300px]" />
    </div>
}