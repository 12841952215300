import { DocumentPlusIcon, TrashIcon } from "@heroicons/react/24/outline";

type UploadFileProps = {
    file: File | null;
    setFile: (file: File | null) => void;
}
export default function UploadFile({ file, setFile }: UploadFileProps) {

    const handleFileChange = (e: any) => {
        setFile(e.target.files[0]);
    };

    return <div className="max-w-full">
        <input
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            className="hidden"
        />

        {/* Custom File Upload Button */}
        <div className="flex mt-2 w-full max-w-full h-20 border-dashed border border-gray-300 rounded-sm justify-center items-center bg-gray-50">
            {!file && <label
                htmlFor="file-upload"
                className="flex flex-row cursor-pointer text-sm font-semibold items-center gap-2 underline hover:no-underline text-blue-600"
            >
                <DocumentPlusIcon className="min-w-5 min-h-5 w-5 h-5 text-blue-600" /> Selecionar arquivo
            </label>}
            {file && <div className="flex flex-row items-center gap-2 w-full max-w-full justify-center overflow-hidden px-4">
                <DocumentPlusIcon className="min-w-9 min-h-9 w-9 h-9 text-blue-600" />
                <div className="flex flex-col overflow-hidden">
                    <h3 className="text-xs text-gray-600">Arquivo selecionado</h3>
                    <div className="flex flex-row gap-4 items-start">
                        <h3 className="text-sm max-w-full text-gray-900 truncate">{file.name}</h3>
                        <TrashIcon className="w-4 h-4 min-w-4 min-h-4 text-red-600 cursor-pointer" onClick={() => setFile(null)} />
                    </div>
                </div>
            </div>}
        </div>
    </div>
}