import queryClient, { QueryKey } from "../hooks/state";
import { CourseModule, CourseModuleFilterParams } from "../models/types";
import { PaginatedResponse } from './../models/types';
import { httpDeleteAuthenticated, httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./Api";

const endpoints = {
  list: () => "/course/modules",
  retrieve: (id: string) => `/course/modules/${id}`,
  nextClass: (id: string) => `/course/modules/${id}/next_class`,
  welcome: () => `/course/modules/welcome_class`,
};

export const getCourseModule = async (objectId: string) => {
  return httpGetAuthenticated<CourseModule>(endpoints.retrieve(objectId));
}

export const getWelcomeClass = async () => {
  return httpGetAuthenticated<{ course_id: string | null; class_id: string | null; module_id: string | null }>(endpoints.welcome());
}

export const getNextClass = async (moduleId: string, currentClass?: string, advance_module?: boolean) => {
  return httpGetAuthenticated<{ class_id: string | null; module_id: string | null }>(endpoints.nextClass(moduleId), {
    params: {
      ...(currentClass && { current: currentClass }),
      ...(advance_module && { advance_module }),
    }
  });
}

export const getCourseModules = async (params: CourseModuleFilterParams) => {
  return httpGetAuthenticated<PaginatedResponse<CourseModule>>(endpoints.list(), {
    params,
  });
}

export const createCourseModule = async (payload: {
  title: string;
  description: string;
  course: string;
  module_number: number;
}) => {
  const response = await httpPostAuthenticated<CourseModule>(endpoints.list(), payload);
  queryClient.invalidateQueries(QueryKey.courseModule);
  return response.data;
}

export const updateCourseModule = async (id: string, payload: {
  title: string;
  description: string;
  course: string;
  module_number: number;
}) => {
  const response = await httpPatchAuthenticated(endpoints.retrieve(id), payload);
  queryClient.invalidateQueries(QueryKey.courseModule);
  return response.data;
}

export const deleteCourseModule = async (id: string) => {
  const response = await httpDeleteAuthenticated(endpoints.retrieve(id));
  queryClient.invalidateQueries(QueryKey.courseModule);
  return response.data;
}