import { LockClosedIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import { useAppContext } from "../context/AppContext";
import { Button } from "./Button";

export default function PaywallButton(props: { className?: string; lockRow?: boolean }) {
    const { className, lockRow = false } = props;
    const baseProps = "h-12 w-12 md:h-12 md:w-12 mt-[-380px] md:mt-[-180px] text-gray-500";
    const { setPaymentModal } = useAppContext();
    return <div className={`flex w-full flex-col ${lockRow ? "md:flex-row" : "md:flex-col"}  justify-center items-center z-10`}>
        <LockClosedIcon className={twMerge(baseProps, className)} />
        <Button className={lockRow ? "mt-2 md:mt-[-60px]" : "mt-2"} title="Exclusivo para assinantes" onClick={() => setPaymentModal(true)} />
    </div>
}