import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { config } from "../config/env";
import { useAuthenticationContext } from "../context/AuthenticationContext";
import { getLogin, getLoginMagicLink } from "../services/LoginService";
import { setCookie } from "../utils/cookies";
import LoadingSpinner from "./Loading";

export function Login() {
    const location = useLocation();
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const navigate = useNavigate();
    const { setUser } = useAuthenticationContext();

    const handleLogin = async () => {
        setLoading(true);
        try {
            const response = await getLogin({ username: email, password });
            if (response.status === 200) {
                setCookie("token", response.data.access);
                setCookie("refresh", response.data.refresh);
                navigate("/inicio");
                setError("");
            } else {
                setError("Usuário ou senha inválidos, tente novamente");
            }
        } catch (e) {
            setError("Usuário ou senha inválidos, tente novamente.");
            console.log("error");
        } finally {
            setLoading(false);
        }
    }

    const loginMagicLink = async (token: string) => {
        try {
            const response = await getLoginMagicLink(token);
            if (response.status === 200) {
                setCookie("token", response.data.access);
                setCookie("refresh", response.data.refresh);
                navigate("/inicio");
                setError("");
            } else {
                setError("Link expirado, solicite um novo ou digite seu e-mail e senha");
            }
        } catch (e) {
            setError("Link expirado, solicite um novo ou digite seu e-mail e senha");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (token) {
            setLoading(true);
            loginMagicLink(token);
        }
    }, [token]);

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-20 w-auto cursor-pointer"
                        onClick={() => navigate("/home")}
                        src="/images/logo.png"
                        alt="Mais um na Bolsa"
                    />
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Acesse sua conta
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <div className="space-y-6">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    E-mail
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoComplete="off"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Senha
                                </label>
                                <div className="mt-2 flex flex-row items-center gap-1">
                                    <input
                                        id="password"
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoComplete="off"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    {!showPassword && <EyeIcon onClick={() => setShowPassword(true)} className="h-5 w-5 text-gray-700 cursor-pointer" />}
                                    {showPassword && <EyeSlashIcon onClick={() => setShowPassword(false)} className="h-5 w-5 text-gray-700 cursor-pointer" />}
                                </div>
                                {error && error !== "" && <h4 className="px-2 py-2 text-xs font-light text-red-600">{error}</h4>}
                            </div>
                            <div>
                                <button
                                    onClick={() => handleLogin()}
                                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    {loading ? <LoadingSpinner /> : 'Acessar'}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="mt-10 text-center text-sm text-gray-500 flex items-center justify-center gap-1">
                        Não tem uma conta?{' '}
                        <div onClick={() => window.open(config.payment.kiwify_checkout_url, "_blank")} className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 cursor-pointer">
                            Assine agora mesmo
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
