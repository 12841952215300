import { BanknotesIcon, CreditCardIcon } from "@heroicons/react/24/outline";

export default function EmptyTable() {
    return <div className="flex flex-grow w-full items-center min-w-full flex-col gap-2 py-6">
        <BanknotesIcon className="h-10 w-10 min-h-10 min-w-10 text-gray-500" />
        <h4 className="text-gray-500 text-md font-semibold">Nenhuma transação</h4>
        <h4 className="text-gray-500 text-sm">Nenhuma transação encontrada para o mês selecionado,<br /> adicione novas transações ou importe das despesas fixas</h4>
    </div>
}

export function EmptyTableCards() {
    return <div className="flex flex-grow w-full items-center min-w-full flex-col gap-2 py-6">
        <CreditCardIcon className="h-10 w-10 min-h-10 min-w-10 text-gray-500" />
        <h4 className="text-gray-500 text-md font-semibold">Nenhuma cartão cadastrado</h4>
        <h4 className="text-gray-500 text-sm">Adicione um cartão para acompanhar suas parcelas e transações</h4>
    </div>
}