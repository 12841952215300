export const handleMoneyChange = (e: any, onChange: (value: string) => void) => {
    const inputValue = e.target.value;
    const numericInput = inputValue.replace(/[^0-9,]/g, '');
    const splitted = numericInput.split(',');
    if (splitted.length > 2) return;
    if (splitted.length === 2 && splitted[1].length > 2) return;
    onChange(numericInput);
};

export const handleNumberChange = (e: any, onChange: (value: any) => void, max?: number, min?: number) => {
    try {
        const inputValue = e.target.value;
        const numericInput = inputValue.replace(/[^0-9]/g, '');
        if (max && Number(numericInput) > max) return;
        if (min && Number(numericInput) < min && inputValue !== "") return;
        onChange(numericInput);
    } catch (e) {
        console.log(e)
    }
}

export const formatToBrazilianCurrency = (value: string) => {
    if (!value) return '';

    // Separar a parte inteira da parte decimal
    const [integerPart, decimalPart] = value.split('.');

    // Formatando a parte inteira com pontos a cada 3 dígitos
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Juntar a parte inteira formatada com a parte decimal usando vírgula
    return decimalPart ? `${formattedIntegerPart},${decimalPart}` : formattedIntegerPart;
};

export const percentageOfIncome = (income: number, value: number) => {
    return Number(((value / income) * 100).toFixed(2));
}