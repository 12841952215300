import Chart from 'react-apexcharts';

type DividendChartProps = {
    labels: string[];
    count: number[];
}

export const CoursesChart = ({ labels, count }: DividendChartProps) => {
    return <Chart options={{
        chart: {
            id: 'apexchart-example',
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: "50%",
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: labels,
            labels: {
                formatter: (value) => (Number.isInteger(value) ? value.toString() : ''), // Show only integers
                style: {
                    fontSize: '12px',
                }
            }
        }
    }} series={[{
        name: 'Dividendos',
        data: count,
    }]} type="bar" width="100%" height={count.length * 50} />
}