import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import AISummary from "../components/AISummary";
import AISummaryPaywall from "../components/AISummaryPaywall";
import BudgetSummary from "../components/BudgetSummary";
import BudgetSummaryPaywall from "../components/BudgetSummaryPaywall";
import CourseCard from "../components/CourseCard";
import ExpenseSummary from "../components/ExpensesSummary";
import ExpenseSummaryPaywall from "../components/ExpenseSummaryPaywall";
import FreeBadge from "../components/FreeBadge";
import GoalSummary from "../components/GoalSummary";
import GoalSummaryPaywall from "../components/GoalSummaryPaywall";
import PaginatedCard from "../components/Pagination";
import StackedSummary from "../components/StackedSummary";
import StackedSummaryPaywall from "../components/StackedSummaryPaywall";
import TitleCard from "../components/TitleCard";
import { useCourses } from "../hooks/useCourses";
import { useMonthlyTransactionsSummary } from "../hooks/useMonthlyTransactions";
import { useUser } from "../hooks/useUsers";
import { Course, CourseFilterParams } from "../models/types";
import { formatBrazilianCurrence } from "../utils/helper";

export default function HomePage() {
    const currentDate = new Date();
    const { data: summary } = useMonthlyTransactionsSummary({ month: format(currentDate, "yyyy-MM-dd") });
    const { data: user } = useUser();

    const isSubscribed = user?.plan && user?.plan !== "FREE";
    const get_current_date = () => format(currentDate, "MMMM/yyyy", { locale: ptBR })

    return <div className="bg-white shadow sm:rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:p-6 max-w-full">
            <div className="flex flex-col gap-4">
                {/* <div className="flex flex-row justify-between py-2 px-4 border-indigo-700 border rounded-md" >
                    <h2 className="text-sm font-medium text-gray-900">Comece por aqui</h2>
                </div> */}
                {!isSubscribed && <>
                    <FreeBadge />
                </>}
                {isSubscribed && <TitleCard title={`Transações de ${get_current_date()}`}>
                    <ExpenseSummary className="border-none" loading={!summary} values={[
                        { label: "Receita", value: `R$ ${formatBrazilianCurrence(summary?.income_transactions_sum ?? 0)}`, className: "text-green-400" },
                        { label: "Despesa Projetada", value: `R$ ${formatBrazilianCurrence(summary?.total_transactions_sum ?? 0)}`, className: "text-red-400" },
                        { label: "Pagamento Pendente", value: `R$ ${formatBrazilianCurrence(summary?.pending_transactions_sum ?? 0)}`, className: "text-gray-400" },
                        { label: "Vencido", value: `R$ ${formatBrazilianCurrence(summary?.due_transactions_sum ?? 0)}`, className: "text-red-400" },
                        { label: "Pago", value: `R$ ${formatBrazilianCurrence(summary?.paid_transactions_sum ?? 0)}`, className: "text-green-400" },
                    ]} /></TitleCard>}
                {!isSubscribed && <ExpenseSummaryPaywall />}
                <div className="flex flex-wrap xl:flex-nowrap gap-4">
                    {isSubscribed &&
                        <>
                            <AISummary />
                            <GoalSummary />
                            <BudgetSummary />
                        </>
                    }
                    {!isSubscribed && <>
                        <AISummaryPaywall />
                        <GoalSummaryPaywall />
                        <BudgetSummaryPaywall />
                    </>
                    }
                </div>
                {isSubscribed && <StackedSummary />}
                {!isSubscribed && <StackedSummaryPaywall />}
                <TitleCard title={`Cursos em destaque`} titleBoxClassName="md:flex-col md:items-start">
                    <PaginatedCard<Course, CourseFilterParams>
                        useGetData={useCourses}
                        pageSize={5}
                        params={{ highlight: true }}
                        render={(course) => <CourseCard key={course.id} course={course} />} />
                </TitleCard>
            </div>
        </div>
    </div>
}