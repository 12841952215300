import { QueryClient } from "react-query";

export enum QueryKey {
  questions = "questions",
  users = "users",
  transactionCategory = "transactionCategory",
  transactionCategoryType = "transactionCategoryType",
  transaction = "transaction",
  answers = "answers",
  paymentIntent = "paymentIntent",
  modules = "modules",
  modulesClasses = "modulesClasses",
  goals = "goals",
  budgetSettings = "budgetSettings",
  stockEvaluation = "stockEvaluation",
  course = "course",
  courseModule = "courseModule",
  courseModuleClass = "courseModuleClass",
  file = "file",
  leads = "leads",
  aiSummary = "aiSummary",
  latestSummary = "latestSummary",
  monthlyTransaction = "monthlyTransaction",
  monthlyTransactionSummary = "monthlyTransactionSummary",
  groupedMonthlyTransactions = "groupedMonthlyTransactions",
  dashboardSummary = "dashboardSummary",
  creditCard = "creditCard",
  creditCardTransaction = "creditCardTransaction",
  courseAdmin = "courseAdmin",
  creditCardTransactionSummary = "creditCardTransactionSummary",
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: 2,
    },
  },
});

export default queryClient;