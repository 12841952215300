import { useCourses } from "../hooks/useCourses";
import { Course, CourseFilterParams } from "../models/types";
import CourseCard from "./CourseCard";
import PaginatedCard from "./Pagination";
import TitleCard from "./TitleCard";

export default function FreeBadge() {
    return <TitleCard title="Material Gratuito" subtitle="Aproveite nosso conteúdo 100% gratuito" titleBoxClassName="bg-orange-500">
        <PaginatedCard<Course, CourseFilterParams>
            useGetData={useCourses}
            pageSize={5}
            params={{ free: true }}
            render={(course) => <CourseCard key={course.id} course={course} />} />
    </TitleCard>
}