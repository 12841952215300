import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { addMonths, format, parseISO } from "date-fns";
import { useState } from "react";
import Card from "../components/Card";
import CreditCardComponent from "../components/CreditCard";
import { EmptyTableCards } from "../components/EmptyTable";
import CreditCardModal from "../components/modals/CreditCardModal";
import PaginatedCard from "../components/Pagination";
import TitleCard from "../components/TitleCard";
import { useCreditCards } from "../hooks/useCreditCards";
import { CreditCard, CreditCardFetchParams } from "../models/types";

export default function CreditCardsPage() {
    const [selectedCard, setSelectedCard] = useState<string | null>(null);
    const [openTransaction, setOpenTransaction] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedTransactionId, setSelectedTransactionId] = useState<string | null>(null);
    const [month, setMonth] = useState<string>(format(new Date(), "yyyy-MM-dd"));
    const handleAdvanceMonth = () => {
        setMonth(format(addMonths(parseISO(month), 1), "yyyy-MM-dd"));
    }
    const handlePreviousMonth = () => {
        setMonth(format(addMonths(parseISO(month), -1), "yyyy-MM-dd"));
    }
    const threeDotsItems = [
        {
            name: 'Adicionar cartão', description: 'Adicione um novo cartão de crédito', onClick: () => {
                setSelectedCard(null)
                setOpen(true);
            }, icon: PlusCircleIcon
        },
    ]

    return <Card>
        <CreditCardModal open={open} onClose={() => {
            setOpen(false);
            setSelectedCard(null);
        }} cardId={selectedCard} />
        <TitleCard
            actions={threeDotsItems}
            boxClassName="flex-row" title={`Cartões de Crédito`} subtitle="Gerencie e acompanhe seus cartões de crédito"
            titleBoxClassName="md:flex-row md:items-center">
            <PaginatedCard<CreditCard, CreditCardFetchParams>
                useGetData={useCreditCards}
                pageSize={5}
                emptyComponent={<EmptyTableCards />}
                params={{}}
                render={(card) => <CreditCardComponent key={card.id} card={card} onClick={() => { setSelectedCard(card.id); setOpen(true) }} />} />
        </TitleCard>
    </Card>
}
