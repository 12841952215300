import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { InputHTMLAttributes } from "react";

type CustomInputProps = {
    fixedPlaceholder?: string;
    title?: string;
    description?: string;
    error: string;
    fullWidth?: boolean;
    bold?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;
export const CustomInput = (props: CustomInputProps) => {
    const { title, description, error, onChange, value, placeholder, name, id, type, fixedPlaceholder, fullWidth = true, bold = false } = props;
    return <div className={`flex flex-col gap-2 ${fullWidth ? "w-full" : ""}`}>
        {title && <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>}
        {description && <h5 className="text-sm text-gray-500">{description}</h5>}
        <div className="relative rounded-md">
            {fixedPlaceholder && <div className="pointer-events-none absolute inset-y-0 top-1 left-0 flex items-center pl-2">
                <span className="text-gray-500 text-xs">{fixedPlaceholder}</span>
            </div>}
            <input
                type={type}
                name={name}
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className={error ?
                    `block ${fullWidth ? "w-full" : ""} rounded-md border-0 py-1.5 ${fixedPlaceholder ? "pl-7" : ""} pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-xs sm:leading-6`
                    :
                    `block ${fullWidth ? "w-full" : ""} h-8 rounded-md border-0 py-1.5 ${fixedPlaceholder ? "pl-7" : ""} text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6`}

            />
            {error !== "" && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon aria-hidden="true" className="h-5 w-5 text-red-500" />
            </div>}
        </div>
        {error !== "" && <p id="email-error" className="text-xs text-red-600">
            {error}
        </p>}
    </div>
}