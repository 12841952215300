import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getWelcomeClass } from "../../services/CourseModuleService";
import { Button } from "../Button";
import BaseModal from "./BaseModal";

type WelcomeModalProps = {
    open: boolean;
    onClose: () => void;
}

export default function WelcomeModal({ open, onClose }: WelcomeModalProps) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const handleNavigate = async () => {
        try {
            setLoading(true);
            const { data } = await getWelcomeClass()
            if (data.course_id) {
                navigate(`/curso/${data.course_id}/modulo/${data.module_id}/aula/${data.class_id}`);
            }
            onClose();
        } catch (e) {
            onClose();
        } finally {
            setLoading(false)
        };
    }

    return (
        <BaseModal open={open} onClose={() => onClose()}>
            <div className="flex w-full flex-col gap-2">
                <h3 className="text-md font-semibold">Bem-vindo(a)!</h3>
                <h3 className="text-xs">Estamos felizes em ter você aqui conosco, com certeza dar esse passo foi uma importante decisão e estaremos juntos nessa jornada transformadora.</h3>
                <h3 className="text-xs">Clique no botão abaixo para dar os primeiros passos!</h3>
                <Button loading={loading} disabled={loading} className="mt-3 py-3 text-sm font-semibold" onClick={async () => handleNavigate()}
                    title="Vamos Lá!" />
            </div>

        </BaseModal>
    )
}
