import { ArrowLeftCircleIcon, ArrowRightCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { addMonths, format, parseISO } from "date-fns";
import { useState } from "react";
import Card from "../components/Card";
import ExpenseSummary from "../components/ExpensesSummary";
import CreditCardTransactionModal from "../components/modals/CreditCardTransactionModal";
import { CreditCardFilters } from "../components/monthly-budget/CreditCardFilters";
import BaseTable from "../components/monthly-budget/Table";
import ThreeDotsMenu from "../components/ThreeDotsMenu";
import { useCreditCardTransactions, useCreditCardTransactionsSummary } from "../hooks/useCreditCards";
import { CreditCardSummaryFetchParams } from "../models/types";
import { formatBrazilianCurrence } from "../utils/helper";
import { creditCardTransactionColumns } from "./shared/CreditCardTransactionsTable";

export default function CreditCardTransactionsPage() {
    const [openTransaction, setOpenTransaction] = useState(false);
    const [params, setParams] = useState<CreditCardSummaryFetchParams>({});
    const [selectedTransactionId, setSelectedTransactionId] = useState<string | null>(null);
    const [month, setMonth] = useState<string>(format(new Date(), "yyyy-MM"));
    const { data: summary } = useCreditCardTransactionsSummary({ ...params, month });
    const handleAdvanceMonth = () => {
        setMonth(format(addMonths(parseISO(month), 1), "yyyy-MM"));
    }
    const handlePreviousMonth = () => {
        setMonth(format(addMonths(parseISO(month), -1), "yyyy-MM"));
    }
    const threeDotsItems = [
        {
            name: 'Adicionar despesa', description: 'Adicione uma nova despesa de cartão de crédito', onClick: () => {
                setSelectedTransactionId(null)
                setOpenTransaction(true);
            }, icon: PlusCircleIcon
        },
    ]

    return <Card>
        <div className="flex gap-2 items-center w-full justify-between">
            <h1 className="text-xl font-bold">Transações no cartão</h1>
            <ThreeDotsMenu className="flex md:hidden" items={threeDotsItems} />
        </div>
        <h4 className="text-sm text-gray-500">
            Aqui você consegue visualizar todos os lançamentos dos seus cartões mês a mês
        </h4>
        <div className="flex flex-row w-full items-end justify-between">
            <div className="flex gap-2 w-full flex-col">
                <label className="text-md font-bold">Mês</label>
                <div className="flex flex-row items-center gap-2">
                    <ArrowLeftCircleIcon onClick={() => handlePreviousMonth()} className="h-5 w-5 text-indigo-700 cursor-pointer" />
                    <h4 className="text-3xl text-gray-900">{format(parseISO(month), "MM/yyyy")}</h4>
                    <ArrowRightCircleIcon onClick={() => handleAdvanceMonth()} className="h-5 w-5 text-indigo-700 cursor-pointer" />
                </div>
            </div>
        </div>
        <div className="flex flex-grow items-end md:items-center flex-col md:flex-row gap-4 justify-between ">
            <CreditCardFilters filters={params} setFilters={setParams} />
            <ThreeDotsMenu className="hidden md:flex" items={threeDotsItems} />
        </div>
        {summary && <ExpenseSummary values={[
            { label: "Maior transação", value: summary.biggest_transaction ?? "Nenhuma despesa", className: "text-gray-400" },
            { label: "Despesa Total", value: `R$ ${formatBrazilianCurrence(summary.total_expense)}`, className: "text-red-400" },
        ]} />}
        <CreditCardTransactionModal open={openTransaction} onClose={() => {
            setSelectedTransactionId(null);
            setOpenTransaction(false);
        }} transactionId={selectedTransactionId} />
        <BaseTable
            useGetData={useCreditCardTransactions}
            onRowClick={(row) => {
                setSelectedTransactionId(row.id)
                setOpenTransaction(true);
            }}
            columns={creditCardTransactionColumns} params={{
                ...params,
                month: format(parseISO(month), "yyyy-MM")
            }} />
    </Card>
}
