import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Card from "../components/Card";
import TransactionCategoryModal from "../components/modals/TransactionCategoryModal";
import BaseTable from "../components/monthly-budget/Table";
import TitleCard from "../components/TitleCard";
import { useTransactionCategories } from "../hooks/useTransactionCategories";
import { categoriesColumns } from "./shared/CategoriesTable";

export default function CategoriesPage() {

    const [open, setOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

    const threeDotsItems = [
        {
            name: 'Adicionar categoria', description: 'Adicione uma nova categoria', onClick: () => {
                setSelectedCategory(null)
                setOpen(true);
            }, icon: PlusCircleIcon
        },
    ]

    return <Card>
        <TransactionCategoryModal open={open} onClose={() => {
            setOpen(false);
            setSelectedCategory(null);
        }} categoryId={selectedCategory} />
        <TitleCard
            actions={threeDotsItems}
            boxClassName="p-0 border-none flex-col" title={`Categorias`} subtitle="Gerencie suas categorias de transações"
            titleBoxClassName="md:flex-row md:items-center">
            <BaseTable
                className="sm:rounded-tl-none sm:rounded-tr-none"
                title={null}
                onRowClick={(row) => {
                    setSelectedCategory(row.id);
                    setOpen(true);
                }}
                columns={categoriesColumns}
                useGetData={useTransactionCategories}
            />
        </TitleCard>
    </Card>
}
