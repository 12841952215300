import { SparklesIcon } from "@heroicons/react/24/outline";
import AIReportReady from "./alberto/ReportReady";
import PaywallButton from "./PaywallButton";

export default function AISummaryPaywall() {
    return <div className="flex flex-col border border-gray-300 rounded-xl w-full sm:w-1/2 flex-grow items-center">
        <div className="flex items-center px-4 py-2 gap-3 bg-gray-100 w-full border-b border-gray-300 rounded-t-xl">
            <SparklesIcon className="h-6 w-6 text-indigo-700" />
            <h3 className="text-sm font-semibold leading-7">
                Relatório da Inteligência Artificial
            </h3>
        </div>
        <div className="flex opacity-20">
            <AIReportReady disabled={true} />
        </div>
        <PaywallButton className="mt-[-350px] md:mt-[-270px]" />
    </div>
}